import { ReactNode } from 'react';

import { ServiceListType, WithdrawalReasonType } from './types';
import { TransPlainText } from './components';
import { Trans } from 'react-i18next';

export const SERVICE_LIST: ServiceListType[] = ['zigzag', 'fbk', 'posty', 'mystore', 'kitto'];

export const WITHDRAWAL_REASON_LIST: Array<{ label: ReactNode; value: WithdrawalReasonType; reason: string }> = [
  {
    label: <TransPlainText ns='auth' i18nKey={'원하는 스토어가 없어요.'} />,
    value: 'store',
    reason: '원하는 스토어가 없어요.',
  },
  { label: <TransPlainText ns='auth' i18nKey='오류가 자주 생겨요.' />, value: 'error', reason: '오류가 자주 생겨요.' },
  {
    label: <TransPlainText ns='auth' i18nKey='과도한 쇼핑을 자제하고 싶어요.' />,
    value: 'lavish',
    reason: '과도한 쇼핑을 자제하고 싶어요.',
  },
  {
    label: <TransPlainText ns='auth' i18nKey='쿠폰/포인트 등의 혜택이 너무 적어요.' />,
    value: 'benefit',
    reason: '쿠폰/포인트 등의 혜택이 너무 적어요.',
  },
  {
    label: <TransPlainText ns='auth' i18nKey='다른 계정으로 다시 가입하고 싶어요.' />,
    value: 'rejoin',
    reason: '다른 계정으로 다시 가입하고 싶어요.',
  },
  { label: <TransPlainText ns='auth' i18nKey='직접 입력' />, value: 'direct', reason: '직접 입력' },
];

export const WITHDRAWAL_REASON_MAP: Record<
  WithdrawalReasonType,
  { title: ReactNode; description: (user_name: string) => ReactNode; action_name: ReactNode }
> = {
  store: {
    title: <Trans ns='auth' i18nKey='원하는 스토어를 요청해주세요.' />,
    description: (user_name) => (
      <Trans
        ns='auth'
        i18nKey='더 많은 스토어를 입점시키기 위해 노력하고 있어요.<br />{{user_name}}님이 요청하신 스토어가 더 빠르게 입점할 수 있도록 최선을 다할게요.'
        values={{ user_name: user_name }}
      />
    ),
    action_name: <Trans ns='auth' i18nKey='스토어 요청하기' />,
  },
  error: {
    title: <Trans ns='auth' i18nKey='오류를 해결해드릴게요.' />,
    description: (user_name) => (
      <Trans
        ns='auth'
        i18nKey='{{user_name}}님이 겪은 불편을 말씀해주시면<br />문제를 해결할 수 있도록 최선을 다해 도와드릴게요.'
        values={{ user_name: user_name }}
      />
    ),
    action_name: <Trans ns='auth' i18nKey='문의하기' />,
  },
  lavish: {
    title: <Trans ns='auth' i18nKey='잠시 쉬었다가 돌아오세요.' />,
    description: (user_name) => (
      <Trans
        ns='auth'
        i18nKey='탈퇴하면 {{user_name}}님의 소중한 기록과 혜택이 모두 사라져요.'
        values={{ user_name: user_name }}
      />
    ),
    action_name: <Trans ns='auth' i18nKey='알겠어요' />,
  },
  benefit: {
    title: <Trans ns='auth' i18nKey='더 많은 혜택을 준비하고 있어요.' />,
    description: (user_name) => (
      <Trans
        ns='auth'
        i18nKey='{{user_name}}님이 원하는 혜택을 제공해드릴 수 있도록<br />최선을 다할게요.'
        values={{ user_name: user_name }}
      />
    ),
    action_name: <Trans ns='auth' i18nKey='혜택 제안하기' />,
  },
  rejoin: {
    title: <Trans ns='auth' i18nKey='이메일을 변경하고 싶으신가요?' />,
    description: () => <Trans ns='auth' i18nKey='기존 데이터는 유지하고 이메일만 변경해드릴게요.' />,
    action_name: <Trans ns='auth' i18nKey='변경 문의하기' />,
  },
  direct: {
    title: '',
    description: () => '',
    action_name: '',
  },
};

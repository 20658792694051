import { ProductCard as V4ProductCard } from '@croquiscom-pvt/zds';

import { useThumbnailNudge, UseThumbnailNudgeOptions } from '../hooks/useThumbnailNudge';

export interface ThumbnailNudgeProps extends UseThumbnailNudgeOptions {
  className?: string;
}

export const ThumbnailNudge = (props: ThumbnailNudgeProps) => {
  const { className, nudgeBadgeList } = props;
  const { hasThumbnailNudge, thumbnailNudgeProps } = useThumbnailNudge({ nudgeBadgeList });

  if (!hasThumbnailNudge) return null;

  return <V4ProductCard.ThumbnailNudge className={className} {...thumbnailNudgeProps} />;
};

/* eslint-disable */
type CrTimestamp = number;
type CrJson = any;
type CrBigInt = number;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUploadReviewImageLink
// ====================================================

export interface CreateUploadReviewImageLink_createUploadLink {
  /**
   * S3 ACL
   */
  acl: string;
  /**
   * S3 formdata upload url
   */
  url: string;
  /**
   * S3 formdata upload key prefix. 항상 /로 끝남. 클라이언트에서는 key_prefix와 파일명을 합쳐 FormData.key에 추가
   */
  key_prefix: string;
  /**
   * S3 signed policy and form data
   */
  fields: CrJson;
}

export interface CreateUploadReviewImageLink {
  /**
   * 파일 업로드 URL을 생성한다
   */
  createUploadLink: CreateUploadReviewImageLink_createUploadLink;
}

export interface CreateUploadReviewImageLinkVariables {
  input: CreateUploadLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSavedShopIdList
// ====================================================

export interface GetSavedShopIdList_saved_shop_id_list {
  shop_id_list: string[] | null;
}

export interface GetSavedShopIdList {
  saved_shop_id_list: GetSavedShopIdList_saved_shop_id_list | null;
}

export interface GetSavedShopIdListVariables {
  id_list: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSavedShopStatus
// ====================================================

export interface GetSavedShopStatus {
  get_saved_shop_status: boolean;
}

export interface GetSavedShopStatusVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveUmdShop
// ====================================================

export interface SaveUmdShop_saveUmdShop_shop {
  id: string;
  shop_main_domain: string;
}

export interface SaveUmdShop_saveUmdShop {
  shop: SaveUmdShop_saveUmdShop_shop;
}

export interface SaveUmdShop {
  saveUmdShop: SaveUmdShop_saveUmdShop;
}

export interface SaveUmdShopVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUmdSavedShop
// ====================================================

export interface RemoveUmdSavedShop {
  removeUmdSavedShop: boolean;
}

export interface RemoveUmdSavedShopVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSavedProductIdList
// ====================================================

export interface GetSavedProductIdList_saved_product_id_list {
  catalog_product_id_list: string[] | null;
}

export interface GetSavedProductIdList {
  saved_product_id_list: GetSavedProductIdList_saved_product_id_list | null;
}

export interface GetSavedProductIdListVariables {
  id_list: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserUUID
// ====================================================

export interface GetUserUUID_user {
  /**
   * 사용자를 구분하기 위한 UUID(version 4). 사용자 기기에서 생성한다
   */
  uuid: string;
}

export interface GetUserUUID {
  /**
   * 주어진 조건 모두에 일치하는 사용자를 받는다.
   * 조건에 맞는 사용자가 없으면 null을 반환한다.
   * 조건이 주어지지 않으면 null을 반환한다.
   */
  user: GetUserUUID_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccountKakaoConnected
// ====================================================

export interface GetUserAccountKakaoConnected_user_account {
  /**
   * 카카오 계정에 연결되었는지 여부
   */
  kakao_connected: boolean;
}

export interface GetUserAccountKakaoConnected {
  user_account: GetUserAccountKakaoConnected_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppleLoginConfig
// ====================================================

export interface GetAppleLoginConfig_getAppleLoginConfig {
  bundle_service_id: string;
  nonce: string;
}

export interface GetAppleLoginConfig {
  /**
   * 릴레이 어택을 예방하기 위해 SHA256 형식의 데이터를 반환합니다
   * [error_list]
   * - invalid_client : 클라이언트 세션 데이터가 올바르지 않을 경우
   */
  getAppleLoginConfig: GetAppleLoginConfig_getAppleLoginConfig;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginForWeb
// ====================================================

export interface LoginForWeb_login {
  /**
   * 성공여부
   */
  success: boolean;
  /**
   * 로그인한 사용자 이름 (로그인 성공시)
   */
  full_name: string | null;
  /**
   * 로그인한 사용자의 UUID (로그인 성공시)
   */
  uuid: string | null;
  /**
   * 에러 코드
   * - auth_authentication_failed: 이메일 또는 비밀번호가 틀립니다
   * - auth_inactive_user_account: 사용자 계정이 휴면 상태입니다
   */
  error_code: string | null;
  /**
   * 에러 메시지
   */
  error_message: string | null;
}

export interface LoginForWeb {
  /**
   * 지그재그 서비스에 로그인한다.
   */
  login: LoginForWeb_login;
}

export interface LoginForWebVariables {
  input: LoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMobileAuthenticationToken
// ====================================================

export interface SendMobileAuthenticationToken {
  /**
   * 휴대폰 인증 번호를 전송한다.
   * [error_code]
   * - mobile_phone_token_send_failed: 문자 메세지 전송에 실패한 경우
   * - too_many_request: 같은 ip로 n분동안 5회 이상 전송한 경우
   */
  sendMobileAuthenticationToken: boolean;
}

export interface SendMobileAuthenticationTokenVariables {
  input: SendMobileAuthenticationTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyMobileAuthenticationToken
// ====================================================

export interface VerifyMobileAuthenticationToken_verifyMobileAuthenticationToken_social_connection_list {
  /**
   * 이메일 정보
   */
  email: string | null;
  /**
   * 카카오 계정 연동여부
   */
  kakao_connected: boolean | null;
  /**
   * 애플 계정 연동여부
   */
  apple_connected: boolean | null;
  /**
   * 구글 계정 연동여부
   */
  google_connected: boolean | null;
  /**
   * 페이스북 계정 연동여부
   */
  facebook_connected: boolean | null;
  /**
   * 최초 가입 경로
   */
  init_type: UserAccountInitType | null;
  /**
   * 비밀번호 유무
   */
  has_password: boolean;
}

export interface VerifyMobileAuthenticationToken_verifyMobileAuthenticationToken {
  /**
   * 이메일 목록
   */
  email_list: string[];
  /**
   * 소셜로그인 계정 연동여부
   */
  social_connection_list: VerifyMobileAuthenticationToken_verifyMobileAuthenticationToken_social_connection_list[];
}

export interface VerifyMobileAuthenticationToken {
  /**
   * 휴대폰 인증 후 이메일을 반환한다.
   * [error_code]
   * - mobile_phone_token_authentication_failed: 휴대폰 번호와 인증번호가 알맞지 않는경우
   */
  verifyMobileAuthenticationToken: VerifyMobileAuthenticationToken_verifyMobileAuthenticationToken;
}

export interface VerifyMobileAuthenticationTokenVariables {
  input: VerifyMobileAuthenticationTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginKakao
// ====================================================

export interface LoginKakao_loginKakao_user_account_kakao_account {
  /**
   * 카카오톡 채널 추가 여부
   */
  plus_friends_status: PlusFriendsType | null;
}

export interface LoginKakao_loginKakao_user_account {
  /**
   * 카카오 계정
   */
  kakao_account: LoginKakao_loginKakao_user_account_kakao_account | null;
}

export interface LoginKakao_loginKakao {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 인증 시점에 계정이 생성됐는지 여부
   */
  is_created: boolean | null;
  /**
   * 가입 완료 메시지
   */
  message: string | null;
  /**
   * 계정 정보
   */
  user_account: LoginKakao_loginKakao_user_account | null;
}

export interface LoginKakao {
  /**
   * 지그재그 서비스에 카카오 계정으로 로그인한다
   * [error_list]
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 로그인 실패
   * - kakao_email_not_found: 카카오 계정에 이메일 정보가 없을 경우
   * - user_account_email_exists: 이미 카카오 계정의 이메일로 가입한 계정이 있을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginKakao: LoginKakao_loginKakao;
}

export interface LoginKakaoVariables {
  input: LoginKakaoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginFacebook
// ====================================================

export interface LoginFacebook_loginFacebook_user_account {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface LoginFacebook_loginFacebook {
  /**
   * 사용자 계정 정보
   */
  user_account: LoginFacebook_loginFacebook_user_account;
}

export interface LoginFacebook {
  /**
   * 페이스북 로그인
   * [error_list]
   * - social_invalid_token: access_token이 잘못된 경우
   * - user_account_invalid_email: 연동된 정보가 없고 이메일정보가 없는 페이스북 계정인 경우
   * - user_account_email_exists: 이미 페이스북 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - inactive_user_account: 휴면계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginFacebook: LoginFacebook_loginFacebook;
}

export interface LoginFacebookVariables {
  input: LoginFacebookInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginApple
// ====================================================

export interface LoginApple_loginApple_user_account {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface LoginApple_loginApple {
  /**
   * 사용자 계정 정보
   */
  user_account: LoginApple_loginApple_user_account;
}

export interface LoginApple {
  /**
   * 애플 로그인
   * [error_list]
   * - invalid_apple_code: 잘못된 애플 코드입니다
   * - user_account_email_exists: 이미 애플 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - auth_invalid_access: 잘못된 접근입니다
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginApple: LoginApple_loginApple;
}

export interface LoginAppleVariables {
  input: LoginAppleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginGoogle
// ====================================================

export interface LoginGoogle_loginGoogle_user_account {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface LoginGoogle_loginGoogle {
  /**
   * 사용자 계정 정보
   */
  user_account: LoginGoogle_loginGoogle_user_account;
}

export interface LoginGoogle {
  /**
   * 구글 로그인
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_exists: 이미 구글 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginGoogle: LoginGoogle_loginGoogle;
}

export interface LoginGoogleVariables {
  input: LoginGoogleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpEmail
// ====================================================

export interface SignUpEmail_signup_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 사용자 휴대폰 번호
   */
  mobile_tel: string | null;
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
}

export interface SignUpEmail_signup {
  /**
   * 가입한 계정 정보
   */
  user_account: SignUpEmail_signup_user_account;
  /**
   * 가입 완료시 메세지
   */
  message: string | null;
}

export interface SignUpEmail {
  /**
   * 회원 가입
   * [error]
   * - auth_duplicated_email: 이미 존재하는 이메일입니다. (일반계정)
   * - auth_invalid_email: 잘못된 이메일입니다.
   * - auth_invalid_password_format: 비밀번호는 6자리 이상입니다.
   * - user_account_email_exists: user_account_email_exists (휴면계정)
   */
  signup: SignUpEmail_signup;
}

export interface SignUpEmailVariables {
  input: SignupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpFacebook
// ====================================================

export interface SignUpFacebook_signupFacebook_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
}

export interface SignUpFacebook_signupFacebook {
  /**
   * 계정 정보
   */
  user_account: SignUpFacebook_signupFacebook_user_account;
  /**
   * 가입 완료시 메시지
   */
  message: string | null;
}

export interface SignUpFacebook {
  /**
   * 페이스북 로그인 및 지그재그 계정 가입
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_is_required: 사용자의 이메일 정보가 없는 경우
   * - user_account_email_exists: 사용자의 이메일 정보로 이미 가입된 계정이 있을 경우
   * - facebook_account_already_connected: 이미 연결된 페이스북 계정이 있는 경우
   */
  signupFacebook: SignUpFacebook_signupFacebook;
}

export interface SignUpFacebookVariables {
  input: SignupFacebookInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpApple
// ====================================================

export interface SignUpApple_signupApple_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
}

export interface SignUpApple_signupApple {
  /**
   * 계정 정보
   */
  user_account: SignUpApple_signupApple_user_account;
  /**
   * 가입 완료시 메시지
   */
  message: string | null;
}

export interface SignUpApple {
  /**
   * 애플 로그인 및 지그재그 계정 가입
   * [error_list]
   * - invalid_apple_code: 잘못된 애플 코드입니다
   * - user_account_email_exists: 이미 애플 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_email_is_required: 사용자의 이메일 정보가 필요합니다.
   */
  signupApple: SignUpApple_signupApple;
}

export interface SignUpAppleVariables {
  input: SignupAppleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpGoogle
// ====================================================

export interface SignUpGoogle_signupGoogle_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
}

export interface SignUpGoogle_signupGoogle {
  /**
   * 계정 정보
   */
  user_account: SignUpGoogle_signupGoogle_user_account;
  /**
   * 가입 완료시 메시지
   */
  message: string | null;
}

export interface SignUpGoogle {
  /**
   * 구글 로그인 및 지그재그 계정 가입
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_exists: 이미 구글 계정의 이메일로 가입한 계정이 있을 경우
   */
  signupGoogle: SignUpGoogle_signupGoogle;
}

export interface SignUpGoogleVariables {
  input: SignupGoogleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUpKakao
// ====================================================

export interface SignUpKakao_signupKakao_user_account_kakao_account {
  /**
   * 카카오톡 채널 추가 여부
   */
  plus_friends_status: PlusFriendsType | null;
}

export interface SignUpKakao_signupKakao_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 카카오 계정
   */
  kakao_account: SignUpKakao_signupKakao_user_account_kakao_account | null;
}

export interface SignUpKakao_signupKakao {
  /**
   * 계정 정보
   */
  user_account: SignUpKakao_signupKakao_user_account;
  /**
   * 가입 완료시 메시지
   */
  message: string | null;
}

export interface SignUpKakao {
  /**
   * 지그재그 서비스에 카카오 계정으로 회원가입한다.
   * [error_list]
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 로그인 실패
   * - kakao_email_not_found: 카카오 계정에 이메일 정보가 없을 경우
   * - user_account_email_exists: 이미 카카오 계정의 이메일로 가입한 계정이 있을 경우
   * - kakao_mobile_tel_not_found: 휴대폰번호가 없는 카카오 계정일 경우
   * - user_account_mobile_tel_exists: 이미 가입된 휴대폰번호로 요청한 경우
   * - user_account_invalid_email: 유효하지 않은 이메일 형식인 경우
   * - deleted_user_account_within_the_period: 재가입 제한 기간 내 재가입 요청한 경우
   */
  signupKakao: SignUpKakao_signupKakao;
}

export interface SignUpKakaoVariables {
  input: SignupKakaoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestUserAccountPasswordReset
// ====================================================

export interface RequestUserAccountPasswordReset {
  /**
   * 계정의 비밀번호 초기화를 요청한다
   */
  requestUserAccountPasswordReset: boolean;
}

export interface RequestUserAccountPasswordResetVariables {
  input: RequestUserAccountPasswordResetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccount
// ====================================================

export interface GetUserAccount_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface GetUserAccount {
  user_account: GetUserAccount_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserProfile
// ====================================================

export interface GetUserProfile_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 사용자 휴대폰 번호
   */
  mobile_tel: string | null;
  /**
   * 본인 인증 여부
   */
  authenticated: boolean | null;
}

export interface GetUserProfile {
  user_account: GetUserProfile_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDisappearingBenefits
// ====================================================

export interface GetDisappearingBenefits_disappearing_benefits {
  /**
   * 화면표기 사용자 이름
   */
  display_name: string | null;
  /**
   * 쿠폰 보유 수량 (전체 사이트)
   */
  count_of_coupons: number;
  /**
   * 포인트 보유 수량 (전체 사이트)
   */
  amount_of_points: number;
  /**
   * 찜한 상품 개수 (전체 사이트)
   */
  count_of_saved_products: number;
  /**
   * 즐겨찾기 개수 (전체 사이트)
   */
  count_of_saved_shops: number;
}

export interface GetDisappearingBenefits {
  /**
   * 탈퇴시 사라지는 혜택 정보
   */
  disappearing_benefits: GetDisappearingBenefits_disappearing_benefits;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectKakaoWithAuth
// ====================================================

export interface ConnectKakaoWithAuth_connectKakaoWithAuth {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface ConnectKakaoWithAuth {
  /**
   * 지그재그 계정에 카카오 계정을 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 연동 실패했습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   */
  connectKakaoWithAuth: ConnectKakaoWithAuth_connectKakaoWithAuth;
}

export interface ConnectKakaoWithAuthVariables {
  input: ConnectKakaoWithAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectFacebookWithAuth
// ====================================================

export interface ConnectFacebookWithAuth_connectFacebookWithAuth {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface ConnectFacebookWithAuth {
  /**
   * 지그재그 계정을 페이스북 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - auth_authentication_failed: 로그인이 실패한 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - facebook_account_already_connected: 이미 연결된 페이스북 계정이 있는 경우
   */
  connectFacebookWithAuth: ConnectFacebookWithAuth_connectFacebookWithAuth;
}

export interface ConnectFacebookWithAuthVariables {
  input: ConnectFacebookWithAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectGoogleWithAuth
// ====================================================

export interface ConnectGoogleWithAuth_connectGoogleWithAuth {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface ConnectGoogleWithAuth {
  /**
   * 지그재그 계정을 구글 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - auth_authentication_failed: 로그인이 실패한 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   */
  connectGoogleWithAuth: ConnectGoogleWithAuth_connectGoogleWithAuth;
}

export interface ConnectGoogleWithAuthVariables {
  input: ConnectGoogleWithAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectAppleWithAuth
// ====================================================

export interface ConnectAppleWithAuth_connectAppleWithAuth {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

export interface ConnectAppleWithAuth {
  /**
   * 지그재그 계정을 애플 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - auth_invalid_access: 잘못된 접근입니다
   */
  connectAppleWithAuth: ConnectAppleWithAuth_connectAppleWithAuth;
}

export interface ConnectAppleWithAuthVariables {
  input: ConnectAppleWithAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindUserAccountEmailByMobileTel
// ====================================================

export interface FindUserAccountEmailByMobileTel {
  /**
   * 해당 휴대폰 번호를 갖고 있는 사용자 계정 email을 반환한다 (이메일은 모두 노출되지 않는다)
   */
  findUserAccountEmailByMobileTel: string | null;
}

export interface FindUserAccountEmailByMobileTelVariables {
  mobile_tel: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserAccount
// ====================================================

export interface UpdateUserAccount {
  /**
   * 사용자 계정 정보를 갱신한다
   */
  updateUserAccount: boolean;
}

export interface UpdateUserAccountVariables {
  input: UpdateUserAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserAccount
// ====================================================

export interface DeleteUserAccount_deleteUserAccount {
  /**
   * 성공여부
   */
  success: boolean;
}

export interface DeleteUserAccount {
  /**
   * 사용자 계정을 삭제한다
   */
  deleteUserAccount: DeleteUserAccount_deleteUserAccount;
}

export interface DeleteUserAccountVariables {
  input: DeleteUserAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsLoggedIn
// ====================================================

export interface IsLoggedIn {
  /**
   * 사용자 계정의 로그인 여부를 리턴한다.
   */
  isLoggedIn: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecertificationKakao
// ====================================================

export interface RecertificationKakao_recertificationKakao {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface RecertificationKakao {
  /**
   * 카카오인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * kakao_connect_failed: 카카오 계정 연결에 실패하였습니다.
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationKakao: RecertificationKakao_recertificationKakao;
}

export interface RecertificationKakaoVariables {
  input: RecertificationSocialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecertificationFacebook
// ====================================================

export interface RecertificationFacebook_recertificationFacebook {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface RecertificationFacebook {
  /**
   * 페이스북인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationFacebook: RecertificationFacebook_recertificationFacebook;
}

export interface RecertificationFacebookVariables {
  input: RecertificationSocialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecertificationApple
// ====================================================

export interface RecertificationApple_recertificationApple {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface RecertificationApple {
  /**
   * 애플인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationApple: RecertificationApple_recertificationApple;
}

export interface RecertificationAppleVariables {
  input: RecertificationAppleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecertificationGoogle
// ====================================================

export interface RecertificationGoogle_recertificationGoogle {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface RecertificationGoogle {
  /**
   * 구글인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationGoogle: RecertificationGoogle_recertificationGoogle;
}

export interface RecertificationGoogleVariables {
  input: RecertificationSocialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecertificationPassword
// ====================================================

export interface RecertificationPassword_recertificationPassword {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface RecertificationPassword {
  /**
   * 사용자가 입력한 비밀번호가 맞는지 자격검증한다.
   * [error_code]
   * - failed_recertification_password: 비밀번호를 다시 확인해주세요.
   */
  recertificationPassword: RecertificationPassword_recertificationPassword;
}

export interface RecertificationPasswordVariables {
  input: RecertificationPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyRecertification
// ====================================================

export interface VerifyRecertification_verifyRecertification {
  /**
   * 자격검증여부
   */
  result: boolean;
}

export interface VerifyRecertification {
  /**
   * 사용자의 자격이 검증되었는지 확인한다.
   * [error_code]
   * need_recertification: 사용자 재인증이 필요합니다.
   */
  verifyRecertification: VerifyRecertification_verifyRecertification;
}

export interface VerifyRecertificationVariables {
  input: VerifyRecertificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAvailableLoginType
// ====================================================

export interface GetUserAvailableLoginType_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 비밀번호 유무
   */
  has_password: boolean;
  /**
   * 카카오 계정에 연결되었는지 여부
   */
  kakao_connected: boolean;
  /**
   * 애플 계정에 연결되었는지 여부
   */
  apple_connected: boolean;
  /**
   * 구글 계정에 연결되었는지 여부
   */
  google_connected: boolean;
  /**
   * 페이스북 계정에 연결되었는지 여부
   */
  facebook_connected: boolean;
}

export interface GetUserAvailableLoginType {
  user_account: GetUserAvailableLoginType_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckAvailableEmail
// ====================================================

export interface CheckAvailableEmail {
  /**
   * 소셜계정에 이메일이 없는 경우 직접 입력한 이메일이 중복되는지 확인한다.
   * [error_code]
   * - user_account_email_exists: 이미 존재하는 이메일인 경우
   */
  checkAvailableEmail: boolean;
}

export interface CheckAvailableEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateInactiveUserAccount
// ====================================================

export interface ActivateInactiveUserAccount_activateInactiveUserAccount {
  /**
   * 성공여부
   */
  success: boolean;
}

export interface ActivateInactiveUserAccount {
  /**
   * 휴면 계정을 활성화한다.
   */
  activateInactiveUserAccount: ActivateInactiveUserAccount_activateInactiveUserAccount | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginMobileWithAuthentication
// ====================================================

export interface LoginMobileWithAuthentication_loginMobileWithAuthentication_user_account {
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 사용자 휴대폰 번호
   */
  mobile_tel: string | null;
  /**
   * 본인 인증 여부
   */
  authenticated: boolean | null;
}

export interface LoginMobileWithAuthentication_loginMobileWithAuthentication {
  /**
   * 사용자 계정 정보
   */
  user_account: LoginMobileWithAuthentication_loginMobileWithAuthentication_user_account;
}

export interface LoginMobileWithAuthentication {
  /**
   * 휴대폰 로그인 시 2차 인증(본인인증)을 하여 로그인한다.
   */
  loginMobileWithAuthentication: LoginMobileWithAuthentication_loginMobileWithAuthentication;
}

export interface LoginMobileWithAuthenticationVariables {
  input: LoginMobileWith2FAInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestForResetPasswordWithAuthentication
// ====================================================

export interface RequestForResetPasswordWithAuthentication_requestForResetPasswordWithAuthentication {
  /**
   * 이메일 정보
   */
  email: string;
  /**
   * 비밀번호 재설정시 유효한 토큰
   */
  token: string;
}

export interface RequestForResetPasswordWithAuthentication {
  /**
   * 본인인증으로 인증된 사용자에게 비밀번호 재설정을 위한 정보를 전달한다.
   * [의도된 error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  requestForResetPasswordWithAuthentication: RequestForResetPasswordWithAuthentication_requestForResetPasswordWithAuthentication;
}

export interface RequestForResetPasswordWithAuthenticationVariables {
  input: RequestForResetPasswordWithAuthenticationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProcessUserAccountAuthenticationResponse
// ====================================================

export interface ProcessUserAccountAuthenticationResponse_processUserAccountAuthenticationResponse {
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 사용자 휴대폰 번호
   */
  mobile_tel: string | null;
  /**
   * 인증 ID
   */
  authenticate_id: string;
}

export interface ProcessUserAccountAuthenticationResponse {
  /**
   * 본인 인증 결과를 처리한다
   * [error_code]
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - user_account_authentication_invalid_input: 올바른 인증 정보가 아닌 경우
   * - user_account_authentication_different_user: 이미 본인인증 한 상태이고, 다른 명의로 본인인증 하는 경우
   * - user_account_authentication_already_exist: 다른 계정에 본인인증이 된 경우
   * - user_account_authentication_under_14_years_old: 14세 이하 본인인증 불가
   * - failed_adult_certification: 성인인증에 실패하였습니다
   */
  processUserAccountAuthenticationResponse: ProcessUserAccountAuthenticationResponse_processUserAccountAuthenticationResponse;
}

export interface ProcessUserAccountAuthenticationResponseVariables {
  input: ProcessUserAccountAuthenticationResponseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAccountAdultCertification
// ====================================================

export interface UserAccountAdultCertification_user_account_adult_certification {
  /**
   * 성인인증 여부
   * - 인증을 받지 않았거나 만료된 경우 false
   */
  is_adult: boolean;
  /**
   * 성인인증 기간만료 여부
   * - 인증을 받았으니 기간이 만료되어서 재인증이 필요한 경우
   */
  is_expired: boolean;
}

export interface UserAccountAdultCertification {
  /**
   * 성인인증 여부 조회
   */
  user_account_adult_certification: UserAccountAdultCertification_user_account_adult_certification;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Findaccountwitauthentication
// ====================================================

export interface Findaccountwitauthentication_find_account_with_authentication {
  /**
   * 이메일 정보
   * - 휴대폰 점유인증일경우 마스킹된 값 반환
   */
  email: string;
  /**
   * 카카오 계정 연동여부
   */
  kakao_connected: boolean;
  /**
   * 애플 계정 연동여부
   */
  apple_connected: boolean;
  /**
   * 구글 계정 연동 여부
   */
  google_connected: boolean;
  /**
   * 페이스북 계정 연동 여부
   */
  facebook_connected: boolean;
  /**
   * 비밀번호 유무
   */
  has_password: boolean;
}

export interface Findaccountwitauthentication {
  /**
   * 본인인증으로 인증된 사용자에게 계정정보를 전달한다.
   * [의도된 error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  find_account_with_authentication: Findaccountwitauthentication_find_account_with_authentication;
}

export interface FindaccountwitauthenticationVariables {
  authenticated_key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProcessKmcAuthentication
// ====================================================

export interface ProcessKmcAuthentication_processKmcAuthentication {
  /**
   * 본인인증완료 처리 후 내부적으로 사용되는 인증키
   */
  authenticated_key: string;
}

export interface ProcessKmcAuthentication {
  /**
   * KMC 본인인증 완료
   * - 응답값으로 계정찾기, 비밀번호재설정 등 사용
   */
  processKmcAuthentication: ProcessKmcAuthentication_processKmcAuthentication;
}

export interface ProcessKmcAuthenticationVariables {
  input: ProcessKmcAuthenticationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserName
// ====================================================

export interface GetUserName_user_account {
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 본인인증 된 사용자 이름
   */
  authenticated_name: string | null;
}

export interface GetUserName {
  user_account: GetUserName_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckBankAccount
// ====================================================

export interface CheckBankAccount {
  /**
   * 계좌 정보 확인하기
   */
  checkBankAccount: boolean;
}

export interface CheckBankAccountVariables {
  bank_code: string;
  bank_account_number: string;
  bank_account_holder: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentNotificationList
// ====================================================

export interface GetPaymentNotificationList_payment_notification_list_item_list {
  /**
   * 알림 타입
   */
  notification_type: PaymentNotificationType;
}

export interface GetPaymentNotificationList_payment_notification_list {
  /**
   * 결제 알림 정보 목록
   */
  item_list: GetPaymentNotificationList_payment_notification_list_item_list[];
}

export interface GetPaymentNotificationList {
  /**
   * 주어진 조건 모두에 일치하는 결제 알림 정보를 반환한다.
   * 조건에 맞는 결제 알림 정보가 없으면 빈 목록을 반환한다.
   */
  payment_notification_list: GetPaymentNotificationList_payment_notification_list;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserOrderList
// ====================================================

export interface GetUserOrderList_user_order_list_item_list_order_item_list {
  /**
   * 상태
   */
  status: OrderItemStatus;
  /**
   * 브라우저 타입
   */
  browsing_type: BrowsingType;
}

export interface GetUserOrderList_user_order_list_item_list {
  /**
   * 국가 정보 code
   */
  country: CountryType;
  /**
   * 결제 수단
   */
  payment_method: PaymentMethod;
  /**
   * 주문 내 상품주문 목록
   */
  order_item_list: GetUserOrderList_user_order_list_item_list_order_item_list[];
}

export interface GetUserOrderList_user_order_list {
  /**
   * 주문 목록
   */
  item_list: GetUserOrderList_user_order_list_item_list[];
  /**
   * 다음 페이지 존재 여부
   */
  has_next: boolean;
}

export interface GetUserOrderList {
  /**
   * 주문배송내역 탭 상태에 해당하는 상품 목록을 반환한다.
   */
  user_order_list: GetUserOrderList_user_order_list;
}

export interface GetUserOrderListVariables {
  status_list?: OrderItemStatus[] | null;
  last_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReviewableItemListCount
// ====================================================

export interface GetReviewableItemListCount_reviewable_order_item_list {
  total_count: number;
}

export interface GetReviewableItemListCount {
  /**
   * 리뷰작성 가능한 구매확정된 사용자의 상품주문 목록을 반환한다.
   */
  reviewable_order_item_list: GetReviewableItemListCount_reviewable_order_item_list;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageInfoForWeb
// ====================================================

export interface GetPageInfoForWeb_page_info_ui_item_list_UxImageBannerGroup {
  __typename: "UxImageBannerGroup" | "UxHybridPromotion" | "UxShopGroup" | "UxImage" | "UxShopExpandableList" | "UxTextTitle" | "UxImageAndTextTitle" | "UxGoodsCarousel" | "UxButton" | "UxLine" | "UxLineWithMargin" | "UxGoodsCardList" | "UxSelectionAndSorting" | "UxSelectionAndCheckBoxAndSorting" | "UxTextAndSorting" | "UxText" | "UxSorting" | "UxSegmentTab" | "UxClpSegmentTab" | "UxCategory" | "UxFullWidthImageBannerGroup" | "UxBandBanner" | "UxGoodsGridGroup" | "UxGoodsGroup" | "UxProductReviewSummaryGroup" | "UxSearchKeywordGuide" | "UxProductListGridGroup" | "UxChipButtonAndProductListGridGroup" | "UxChipButtonGridGroup" | "UxBrandThemeContent" | "UxWebPage" | "UxShopRankingCardItem" | "UxMultilineRankingCarousel" | "UxCheckButtonAndSorting" | "UxClpCategoryList" | "UxQuickMenu" | "UxEntryBanner" | "UxNoResults" | "UxAdDisplayClpBanner" | "UxAdDisplayLargeBanner" | "UxAdDisplayMidBanner" | "UxAdDisplaySmallBanner" | "UxAdDisplayXLargeBanner" | "UxAdDisplayXMidBanner" | "UxAdDisplayBannerGroup" | "UxTextAndMoreButton" | "UxSearchedShopCarousel" | "UxGoodsFilterList" | "UxDdpTemplate" | "UxSingleBanner" | "UxSearchResultHeader" | "UxSearchResultQuickFilterList" | "UxBrandRecommendGroup" | "UxBannerItem" | "UxThemeComponentCarousel" | "UxBrandChipProductListGroup" | "UxErrorComponent" | "UxThemeRanking" | "UxTimeDealCard" | "UxAdBannerCard" | "UxBenefitsCard" | "ProductCardUxComponent" | "UxPromotionFloatingBanner" | "UxPromotionTC";
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery_color {
  normal: string | null;
  dark: string | null;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery_html {
  normal: string | null;
  dark: string | null;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery {
  text: string;
  color: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery_color | null;
  html: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery_html | null;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_final_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_max_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem {
  __typename: "UxGoodsCardItem";
  browsing_type: UxBrowsingType;
  position: number;
  type: UxComponentType;
  image_url: string | null;
  webp_image_url: string | null;
  jpeg_image_url: string | null;
  video_url: string | null;
  log: string | null;
  image_ratio: number | null;
  aid: string | null;
  uuid: string | null;
  product_url: string | null;
  shop_id: string;
  shop_product_no: string | null;
  shop_name: string | null;
  title: string | null;
  discount_rate: number | null;
  discount_info: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_discount_info | null;
  column_count: number | null;
  catalog_product_id: string | null;
  goods_id: string | null;
  one_day_delivery: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_one_day_delivery | null;
  has_coupon: boolean;
  is_zpay_discount: boolean;
  price: number | null;
  final_price: number;
  final_price_with_currency: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_final_price_with_currency;
  max_price: number;
  max_price_with_currency: GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem_max_price_with_currency;
  is_zonly: boolean;
  is_brand: boolean;
  free_shipping: boolean | null;
  zpay: boolean | null;
  ranking: number | null;
  sellable_status: UxSellableStatus;
  review_score: number | null;
  display_review_count: string | null;
  is_ad: boolean;
  is_exclusive: boolean;
  similar_search: boolean | null;
}

export type GetPageInfoForWeb_page_info_ui_item_list = GetPageInfoForWeb_page_info_ui_item_list_UxImageBannerGroup | GetPageInfoForWeb_page_info_ui_item_list_UxGoodsCardItem;

export interface GetPageInfoForWeb_page_info {
  page_name: string | null;
  has_next: boolean | null;
  end_cursor: string | null;
  type: string;
  ui_item_list: GetPageInfoForWeb_page_info_ui_item_list[];
}

export interface GetPageInfoForWeb {
  page_info: GetPageInfoForWeb_page_info | null;
}

export interface GetPageInfoForWebVariables {
  page_id?: string | null;
  category_id?: number | null;
  sorting_id?: number | null;
  age_filter_id?: number | null;
  after?: string | null;
  base_shop_id?: string | null;
  goods_filter_option?: GoodsFilterOptionInput | null;
  filter_id_list?: string[] | null;
  ui_property?: UiPropertyInput | null;
  department_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Logout
// ====================================================

export interface Logout {
  /**
   * 지그재그 서비스에서 로그아웃 한다.
   */
  logout: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetkakaoPayMyPageBannerList
// ====================================================

export interface GetkakaoPayMyPageBannerList_kakao_pay_my_page_banner_list_item_list {
  id: string;
  image_url: string | null;
  landing_url: string | null;
  position: number | null;
  log: string | null;
}

export interface GetkakaoPayMyPageBannerList_kakao_pay_my_page_banner_list {
  id: string;
  position: number;
  type: UxComponentType;
  aspect_ratio: number | null;
  is_auto_rolling: boolean | null;
  update_interval: number | null;
  item_list: GetkakaoPayMyPageBannerList_kakao_pay_my_page_banner_list_item_list[];
}

export interface GetkakaoPayMyPageBannerList {
  /**
   * 카카오 페이 전용 마이페이지 배너 목록
   */
  kakao_pay_my_page_banner_list: GetkakaoPayMyPageBannerList_kakao_pay_my_page_banner_list | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccountPointInfo
// ====================================================

export interface GetUserAccountPointInfo_user_account_point_info {
  /**
   * 총 사용 가능 포인트
   */
  available_point_of_use: number;
  /**
   * 지정 기간내 만료 예정 포인트
   */
  expire_point: number;
}

export interface GetUserAccountPointInfo {
  /**
   * 해당 사용자의 포인트 현황을 가져온다
   */
  user_account_point_info: GetUserAccountPointInfo_user_account_point_info;
}

export interface GetUserAccountPointInfoVariables {
  expire_days: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetZigzagNotice
// ====================================================

export interface GetZigzagNotice_zigzag_notice {
  /**
   * 레코드 ID
   */
  id: string;
  /**
   * 제목
   */
  title: string;
  /**
   * 내용
   */
  contents: string;
  /**
   * 공지 내용 (HTML)
   */
  contents_html: string;
  /**
   * 카테고리
   */
  category: string;
  /**
   * 공지 날짜 ex) 20160101
   */
  date_ymd: number;
  /**
   * os
   */
  os: ZigzagNoticeOsType | null;
  /**
   * 공지사항에 필요한 link url
   */
  link: string | null;
  /**
   * 공지사항 목록 상단 고정 여부
   */
  is_pinned: boolean;
}

export interface GetZigzagNotice {
  /**
   * ID에 해당하는 공지사항을 반환한다.
   */
  zigzag_notice: GetZigzagNotice_zigzag_notice | null;
}

export interface GetZigzagNoticeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetZigzagNoticeList
// ====================================================

export interface GetZigzagNoticeList_zigzag_notice_list_item_list {
  /**
   * 레코드 ID
   */
  id: string;
  /**
   * 제목
   */
  title: string;
  /**
   * 내용 요약
   */
  shorten_contents: string;
  /**
   * os
   */
  os: ZigzagNoticeOsType | null;
  /**
   * 공지 날짜 ex) 20160101
   */
  date_ymd: number;
  /**
   * 카테고리
   */
  category: string;
  /**
   * 공지사항 목록 상단 고정 여부
   */
  is_pinned: boolean;
}

export interface GetZigzagNoticeList_zigzag_notice_list {
  /**
   * 조건을 만족하는 공지사항 수 (페이지네이션 조건 제외)
   */
  total_count: number;
  /**
   * 조건을 만족하는 고정 공지사항 수 (페이지네이션 조건 제외)
   */
  pinned_count: number;
  /**
   * 공지사항 목록
   */
  item_list: GetZigzagNoticeList_zigzag_notice_list_item_list[];
}

export interface GetZigzagNoticeList {
  /**
   * 공지 목록을 최신순으로 반환한다.
   */
  zigzag_notice_list: GetZigzagNoticeList_zigzag_notice_list;
}

export interface GetZigzagNoticeListVariables {
  os?: ZigzagNoticeOsType | null;
  category?: string | null;
  date_ymd_gte?: string | null;
  date_ymd_lte?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogProductForQna
// ====================================================

export interface GetCatalogProductForQna_catalog_product_detail_page_shop {
  id: string;
  name: string;
  main_domain: string;
  typical_image_url: string | null;
}

export interface GetCatalogProductForQna_catalog_product_detail_page_product_product_image_list {
  /**
   * 이미지 URL
   */
  url: string | null;
  /**
   * 이미지 타입
   */
  image_type: PdpCatalogProductImageType;
}

export interface GetCatalogProductForQna_catalog_product_detail_page_product_product_price {
  /**
   * 할인가
   */
  discount_price: number;
  /**
   * 할인양
   */
  discount_amount: number;
  /**
   * 할인률
   */
  discount_rate: number;
  /**
   * 정상가
   */
  original_price: number;
}

export interface GetCatalogProductForQna_catalog_product_detail_page_product {
  id: string;
  /**
   * 상품명
   */
  name: string;
  /**
   * 상품 url
   */
  product_url: string;
  /**
   * 상품 타입
   */
  product_type: PdpCatalogProductType;
  /**
   * 상품의 이미지 리스트
   */
  product_image_list: GetCatalogProductForQna_catalog_product_detail_page_product_product_image_list[];
  /**
   * 상품의 가격 정보
   */
  product_price: GetCatalogProductForQna_catalog_product_detail_page_product_product_price;
}

export interface GetCatalogProductForQna_catalog_product_detail_page {
  /**
   * 쇼핑몰 정보
   */
  shop: GetCatalogProductForQna_catalog_product_detail_page_shop;
  /**
   * 입점형 내재화 상품정보
   */
  product: GetCatalogProductForQna_catalog_product_detail_page_product;
}

export interface GetCatalogProductForQna {
  /**
   * PDP 기본 정보 (source = 네이버 등 제휴사를 통해 유입된 캠페인 코드)
   */
  catalog_product_detail_page: GetCatalogProductForQna_catalog_product_detail_page | null;
}

export interface GetCatalogProductForQnaVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCatalogProductQna
// ====================================================

export interface CreateCatalogProductQna {
  /**
   * 상품 문의 등록
   */
  createCatalogQuestion: string;
}

export interface CreateCatalogProductQnaVariables {
  input: CatalogQuestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSiteList
// ====================================================

export interface GetSiteList_site_list_item_list {
  id: string;
  /**
   * 사이트명
   */
  name: string;
}

export interface GetSiteList_site_list {
  item_list: GetSiteList_site_list_item_list[];
}

export interface GetSiteList {
  /**
   * 사이트 목록을 반환한다
   */
  site_list: GetSiteList_site_list;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRelatedProductReviewSummary
// ====================================================

export interface GetRelatedProductReviewSummary_related_product_review_summary_rating_list {
  /**
   * 평점
   */
  rating: number;
  count: number;
  ratio: number;
}

export interface GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_question {
  label: string;
  value: string;
  category: string;
}

export interface GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_most_answer {
  label: string;
  value: string;
  count: number;
  ratio: number;
}

export interface GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_answer_list {
  label: string;
  value: string;
  count: number;
  ratio: number;
  percent: number | null;
}

export interface GetRelatedProductReviewSummary_related_product_review_summary_attribute_list {
  /**
   * 질문
   */
  question: GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_question;
  /**
   * 최다 답변
   */
  most_answer: GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_most_answer | null;
  /**
   * 답변
   */
  answer_list: GetRelatedProductReviewSummary_related_product_review_summary_attribute_list_answer_list[];
}

export interface GetRelatedProductReviewSummary_related_product_review_summary {
  /**
   * 평점 평균
   */
  ratings_average: number;
  /**
   * 평점 정보의 배열
   */
  rating_list: GetRelatedProductReviewSummary_related_product_review_summary_rating_list[];
  /**
   * 특성평가 배열
   */
  attribute_list: GetRelatedProductReviewSummary_related_product_review_summary_attribute_list[];
}

export interface GetRelatedProductReviewSummary {
  /**
   * 연관 상품 리뷰 요약 정보를 조회한다.
   */
  related_product_review_summary: GetRelatedProductReviewSummary_related_product_review_summary;
}

export interface GetRelatedProductReviewSummaryVariables {
  product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProductReviewBestReviewList
// ====================================================

export interface GetProductReviewBestReviewList_product_review_best_review_list_item_list_requested_user {
  is_abuse_reported: boolean;
}

export interface GetProductReviewBestReviewList_product_review_best_review_list_item_list_reviewer {
  /**
   * 마스킹 처리된 이메일
   */
  masked_email: string;
}

export interface GetProductReviewBestReviewList_product_review_best_review_list_item_list_attachment_list {
  /**
   * 상품 리뷰 유형
   */
  type: ProductReviewType;
  /**
   * 원본 파일 URL
   */
  original_url: string;
  /**
   * 썸네일 파일 URL
   */
  thumbnail_url: string;
  /**
   * 상품리뷰 첨부파일 상태
   */
  status: ProductReviewAttachmentStatus;
}

export interface GetProductReviewBestReviewList_product_review_best_review_list_item_list {
  /**
   * 레코드 ID
   */
  id: string;
  /**
   * 리뷰 내용
   */
  contents: string;
  /**
   * 평점
   */
  rating: number;
  /**
   * 생성 날짜
   */
  date_created: CrTimestamp;
  /**
   * 수정 날짜
   */
  date_updated: CrTimestamp | null;
  /**
   * 요청한 사용자별 정보
   */
  requested_user: GetProductReviewBestReviewList_product_review_best_review_list_item_list_requested_user | null;
  /**
   * 리뷰 작성자 사용자계정 정보
   */
  reviewer: GetProductReviewBestReviewList_product_review_best_review_list_item_list_reviewer;
  /**
   * 상품 리뷰 첨부파일 목록
   */
  attachment_list: GetProductReviewBestReviewList_product_review_best_review_list_item_list_attachment_list[];
}

export interface GetProductReviewBestReviewList_product_review_best_review_list {
  total_count: number;
  item_list: GetProductReviewBestReviewList_product_review_best_review_list_item_list[];
}

export interface GetProductReviewBestReviewList {
  /**
   * 리뷰 이벤트에 선정된 상품 리뷰 목록을 반환한다.
   */
  product_review_best_review_list: GetProductReviewBestReviewList_product_review_best_review_list;
}

export interface GetProductReviewBestReviewListVariables {
  product_id: string;
  limit_count?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogProductSizeRecommendation
// ====================================================

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_height_range {
  min: number;
  max: number;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_weight_range {
  min: number;
  max: number;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_answer {
  percent: number;
  value: string;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list {
  ranking: number;
  name: string;
  height_range: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_height_range | null;
  weight_range: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_weight_range | null;
  size_with_category: string | null;
  answer: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list_answer | null;
  purchase_percent: number | null;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_user_account_body {
  /**
   * 신장
   */
  height: number;
  /**
   * 체중
   */
  weight: number;
  /**
   * 사이즈에 대한 문구, e.g. '하의 27'
   */
  size_text: string | null;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_user_account {
  name: string | null;
  body: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_user_account_body | null;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation {
  recommendation_type: PdpProductSizeType;
  option_value_list: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_option_value_list[];
  user_account: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation_user_account | null;
}

export interface GetCatalogProductSizeRecommendation_catalog_product_size_recommendation {
  /**
   * 사이즈 추천 정보
   */
  size_recommendation: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation_size_recommendation | null;
}

export interface GetCatalogProductSizeRecommendation {
  /**
   * PDP 기본 정보 (source = 네이버 등 제휴사를 통해 유입된 캠페인 코드)
   */
  catalog_product_size_recommendation: GetCatalogProductSizeRecommendation_catalog_product_size_recommendation | null;
}

export interface GetCatalogProductSizeRecommendationVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogBrowsingType
// ====================================================

export interface GetCatalogBrowsingType_catalog_product_detail_browsing_type_product {
  /**
   * 브라우저 타입
   */
  browsing_type: PdpBrowsingType;
}

export interface GetCatalogBrowsingType_catalog_product_detail_browsing_type {
  /**
   * 입점형 내재화 상품정보
   */
  product: GetCatalogBrowsingType_catalog_product_detail_browsing_type_product;
}

export interface GetCatalogBrowsingType {
  /**
   * PDP 기본 정보 (source = 네이버 등 제휴사를 통해 유입된 캠페인 코드)
   */
  catalog_product_detail_browsing_type: GetCatalogBrowsingType_catalog_product_detail_browsing_type | null;
}

export interface GetCatalogBrowsingTypeVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatalogProductEpick
// ====================================================

export interface GetCatalogProductEpick_pdp_base_info_epick_list {
  id: string;
  /**
   * 게시글 이미지
   */
  image_url: string;
  /**
   * 랜딩 URL
   */
  landing_url: string;
  /**
   * 에픽 유저 닉네임
   */
  nickname: string;
}

export interface GetCatalogProductEpick_pdp_base_info {
  /**
   * 연관된 에픽 정보
   */
  epick_list: GetCatalogProductEpick_pdp_base_info_epick_list[];
}

export interface GetCatalogProductEpick {
  /**
   * PDP 기본 정보 (source = 네이버 등 제휴사를 통해 유입된 캠페인 코드)
   */
  pdp_base_info: GetCatalogProductEpick_pdp_base_info | null;
}

export interface GetCatalogProductEpickVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRelatedItemList
// ====================================================

export interface GetRelatedItemList_related_item_list_PdpRecommendCardGroup {}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpRecommendCardGroup {}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_main_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_sub_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list {
  /**
   * 광고 뱃지 여부
   */
  is_ad: boolean;
  /**
   * 컴포넌트 type
   */
  type: PdpComponentType;
  /**
   * 쇼핑몰 id
   */
  shop_id: string;
  /**
   * 쇼핑몰 이름
   */
  shop_name: string | null;
  /**
   * 쇼핑몰에서 부여한 고유 product_no
   */
  shop_product_no: string | null;
  position: number;
  /**
   * 원본 image_url
   */
  image_url: string | null;
  /**
   * 썸네일화 webp_image_url
   */
  webp_image_url: string | null;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid: string | null;
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge: boolean | null;
  /**
   * 상품 클릭시 이동할 url 주소
   */
  product_url: string | null;
  /**
   * 상품 이름
   */
  title: string | null;
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price: number | null;
  /**
   * 상품의 할인율
   */
  discount_rate: number | null;
  /**
   * 무료배송 여부
   */
  free_shipping: boolean | null;
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id: string | null;
  /**
   * zpay 상품 여부
   */
  zpay: boolean | null;
  /**
   * 상품이 한줄에 몇개 표기할지
   */
  column_count: number | null;
  /**
   * 상품 카드에 숫자를 표기하기 위한 필드
   */
  ranking: number | null;
  /**
   * 할인가 표기 정보
   */
  discount_info: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_discount_info | null;
  /**
   * 모든 할인이 적용된 최종 가격
   */
  final_price: number;
  /**
   * 연동된 가격중 가장 높은 가격
   */
  max_price: number;
  /**
   * catalog_product_id
   */
  catalog_product_id: string | null;
  /**
   * 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부
   */
  browsing_type: PdpBrowsingType;
  /**
   * log
   */
  log: string | null;
  /**
   * 광고 id
   */
  aid: string | null;
  /**
   * 이미지 비율
   */
  image_ratio: number | null;
  /**
   * 상품의 리뷰 갯수
   */
  review_count: number | null;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: boolean;
  /**
   * zonly 상품 여부
   */
  is_zonly: boolean;
  /**
   * 브랜드 상품 여부
   */
  is_brand: boolean;
  /**
   * Catalog 상품의 상태 (판매중, 품절)
   */
  sales_status: PdpSearchedProductSalesStatus | null;
  /**
   * 상품의 리뷰 갯수 (#,### 형태)
   */
  display_review_count: string | null;
  /**
   * 상품의 리뷰 점수
   */
  review_score: number | null;
  /**
   * 뱃지 리스트
   */
  badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * 브랜드 네임 우측 뱃지 리스트
   */
  brand_name_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list[] | null;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list_managed_category_list[] | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel {
  /**
   * 상품 카드 컴포넌트 리스트
   */
  component_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel_component_list[];
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup {
  position: number;
  type: PdpComponentType;
  /**
   * 사용 목적 타입
   */
  group_type: string;
  /**
   * 광고 캐러셀 여부
   */
  is_ad: boolean;
  /**
   * 메인 타이틀
   */
  main_title: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_main_title;
  /**
   * 서브 타이틀
   */
  sub_title: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_sub_title | null;
  /**
   * 더보기 버튼
   */
  more_button: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_more_button | null;
  /**
   * 상품 캐로셀
   */
  goods_carousel: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup_goods_carousel;
}

export type GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area = GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpRecommendCardGroup | GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area_PdpGoodsGroup;

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpRecommendCardGroup {}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_main_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_sub_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list {
  /**
   * 광고 뱃지 여부
   */
  is_ad: boolean;
  /**
   * 컴포넌트 type
   */
  type: PdpComponentType;
  /**
   * 쇼핑몰 id
   */
  shop_id: string;
  /**
   * 쇼핑몰 이름
   */
  shop_name: string | null;
  /**
   * 쇼핑몰에서 부여한 고유 product_no
   */
  shop_product_no: string | null;
  position: number;
  /**
   * 원본 image_url
   */
  image_url: string | null;
  /**
   * 썸네일화 webp_image_url
   */
  webp_image_url: string | null;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid: string | null;
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge: boolean | null;
  /**
   * 상품 클릭시 이동할 url 주소
   */
  product_url: string | null;
  /**
   * 상품 이름
   */
  title: string | null;
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price: number | null;
  /**
   * 상품의 할인율
   */
  discount_rate: number | null;
  /**
   * 무료배송 여부
   */
  free_shipping: boolean | null;
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id: string | null;
  /**
   * zpay 상품 여부
   */
  zpay: boolean | null;
  /**
   * 상품이 한줄에 몇개 표기할지
   */
  column_count: number | null;
  /**
   * 상품 카드에 숫자를 표기하기 위한 필드
   */
  ranking: number | null;
  /**
   * 할인가 표기 정보
   */
  discount_info: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_discount_info | null;
  /**
   * 모든 할인이 적용된 최종 가격
   */
  final_price: number;
  /**
   * 연동된 가격중 가장 높은 가격
   */
  max_price: number;
  /**
   * catalog_product_id
   */
  catalog_product_id: string | null;
  /**
   * 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부
   */
  browsing_type: PdpBrowsingType;
  /**
   * log
   */
  log: string | null;
  /**
   * 광고 id
   */
  aid: string | null;
  /**
   * 이미지 비율
   */
  image_ratio: number | null;
  /**
   * 상품의 리뷰 갯수
   */
  review_count: number | null;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: boolean;
  /**
   * zonly 상품 여부
   */
  is_zonly: boolean;
  /**
   * 브랜드 상품 여부
   */
  is_brand: boolean;
  /**
   * Catalog 상품의 상태 (판매중, 품절)
   */
  sales_status: PdpSearchedProductSalesStatus | null;
  /**
   * 상품의 리뷰 갯수 (#,### 형태)
   */
  display_review_count: string | null;
  /**
   * 상품의 리뷰 점수
   */
  review_score: number | null;
  /**
   * 뱃지 리스트
   */
  badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * 브랜드 네임 우측 뱃지 리스트
   */
  brand_name_badge_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list[] | null;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list_managed_category_list[] | null;
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel {
  /**
   * 상품 카드 컴포넌트 리스트
   */
  component_list: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel_component_list[];
}

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup {
  position: number;
  type: PdpComponentType;
  /**
   * 사용 목적 타입
   */
  group_type: string;
  /**
   * 광고 캐러셀 여부
   */
  is_ad: boolean;
  /**
   * 메인 타이틀
   */
  main_title: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_main_title;
  /**
   * 서브 타이틀
   */
  sub_title: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_sub_title | null;
  /**
   * 더보기 버튼
   */
  more_button: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_more_button | null;
  /**
   * 상품 캐로셀
   */
  goods_carousel: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup_goods_carousel;
}

export type GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area = GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpRecommendCardGroup | GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area_PdpGoodsGroup;

export interface GetRelatedItemList_related_item_list_PDPRecommendGroup {
  /**
   * PDP 상단 추천 영역 리스트
   */
  top_area: GetRelatedItemList_related_item_list_PDPRecommendGroup_top_area[];
  /**
   * PDP 하단 추천 영역 리스트
   */
  bottom_area: GetRelatedItemList_related_item_list_PDPRecommendGroup_bottom_area[];
}

export type GetRelatedItemList_related_item_list = GetRelatedItemList_related_item_list_PdpRecommendCardGroup | GetRelatedItemList_related_item_list_PDPRecommendGroup;

export interface GetRelatedItemList {
  /**
   * WEB 카탈로그 PDP 상단, 하단 영역 추천 상품
   */
  related_item_list: GetRelatedItemList_related_item_list[];
}

export interface GetRelatedItemListVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPdpRecommendItemList
// ====================================================

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpRecommendCardGroup {}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_main_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_sub_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list {
  /**
   * 광고 뱃지 여부
   */
  is_ad: boolean;
  /**
   * 컴포넌트 type
   */
  type: PdpComponentType;
  /**
   * 쇼핑몰 id
   */
  shop_id: string;
  /**
   * 쇼핑몰 이름
   */
  shop_name: string | null;
  /**
   * 쇼핑몰에서 부여한 고유 product_no
   */
  shop_product_no: string | null;
  position: number;
  /**
   * 원본 image_url
   */
  image_url: string | null;
  /**
   * 썸네일화 webp_image_url
   */
  webp_image_url: string | null;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid: string | null;
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge: boolean | null;
  /**
   * 상품 클릭시 이동할 url 주소
   */
  product_url: string | null;
  /**
   * 상품 이름
   */
  title: string | null;
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price: number | null;
  /**
   * 상품의 할인율
   */
  discount_rate: number | null;
  /**
   * 무료배송 여부
   */
  free_shipping: boolean | null;
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id: string | null;
  /**
   * zpay 상품 여부
   */
  zpay: boolean | null;
  /**
   * 상품이 한줄에 몇개 표기할지
   */
  column_count: number | null;
  /**
   * 상품 카드에 숫자를 표기하기 위한 필드
   */
  ranking: number | null;
  /**
   * 할인가 표기 정보
   */
  discount_info: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_discount_info | null;
  /**
   * 모든 할인이 적용된 최종 가격
   */
  final_price: number;
  /**
   * 연동된 가격중 가장 높은 가격
   */
  max_price: number;
  /**
   * catalog_product_id
   */
  catalog_product_id: string | null;
  /**
   * 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부
   */
  browsing_type: PdpBrowsingType;
  /**
   * log
   */
  log: string | null;
  /**
   * 광고 id
   */
  aid: string | null;
  /**
   * 이미지 비율
   */
  image_ratio: number | null;
  /**
   * 상품의 리뷰 갯수
   */
  review_count: number | null;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: boolean;
  /**
   * zonly 상품 여부
   */
  is_zonly: boolean;
  /**
   * 브랜드 상품 여부
   */
  is_brand: boolean;
  /**
   * Catalog 상품의 상태 (판매중, 품절)
   */
  sales_status: PdpSearchedProductSalesStatus | null;
  /**
   * 상품의 리뷰 갯수 (#,### 형태)
   */
  display_review_count: string | null;
  /**
   * 상품의 리뷰 점수
   */
  review_score: number | null;
  /**
   * 뱃지 리스트
   */
  badge_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * 브랜드 네임 우측 뱃지 리스트
   */
  brand_name_badge_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_brand_name_badge_list[] | null;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list_managed_category_list[] | null;
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel {
  /**
   * 상품 카드 컴포넌트 리스트
   */
  component_list: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel_component_list[];
}

export interface GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup {
  position: number;
  type: PdpComponentType;
  /**
   * 사용 목적 타입
   */
  group_type: string;
  /**
   * 광고 캐러셀 여부
   */
  is_ad: boolean;
  /**
   * 메인 타이틀
   */
  main_title: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_main_title;
  /**
   * 서브 타이틀
   */
  sub_title: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_sub_title | null;
  /**
   * 더보기 버튼
   */
  more_button: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_more_button | null;
  /**
   * 상품 캐로셀
   */
  goods_carousel: GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup_goods_carousel;
}

export type GetPdpRecommendItemList_pdp_recommend_item_list = GetPdpRecommendItemList_pdp_recommend_item_list_PdpRecommendCardGroup | GetPdpRecommendItemList_pdp_recommend_item_list_PdpGoodsGroup;

export interface GetPdpRecommendItemList {
  /**
   * 카탈로그 PDP 추천 상품
   */
  pdp_recommend_item_list: GetPdpRecommendItemList_pdp_recommend_item_list[];
}

export interface GetPdpRecommendItemListVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPdpShortFormContents
// ====================================================

export interface GetPdpShortFormContents_pdp_short_form_contents_content_list {
  id: string;
  title: string;
  thumbnail_image_url: string;
  video_url: string;
}

export interface GetPdpShortFormContents_pdp_short_form_contents {
  content_list: GetPdpShortFormContents_pdp_short_form_contents_content_list[] | null;
}

export interface GetPdpShortFormContents {
  /**
   * 숏폼 콘텐츠
   */
  pdp_short_form_contents: GetPdpShortFormContents_pdp_short_form_contents | null;
}

export interface GetPdpShortFormContentsVariables {
  catalog_product_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReceiveMembershipBenefitInPdp
// ====================================================

export interface ReceiveMembershipBenefitInPdp {
  /**
   * 멤버십 혜택 받기
   */
  receiveMembershipBenefit: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPdpMetaFeatureList
// ====================================================

export interface GetPdpMetaFeatureList_metadata {
  /**
   * 활성화 기능 목록
   */
  feature_list: string[];
}

export interface GetPdpMetaFeatureList {
  /**
   * 메타데이터를 반환한다.
   */
  metadata: GetPdpMetaFeatureList_metadata;
}

export interface GetPdpMetaFeatureListVariables {
  ui?: string | null;
  platfrom: string;
  data_version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccountBody
// ====================================================

export interface GetUserAccountBody_user_account_body_height {
  value: number;
  label: string;
}

export interface GetUserAccountBody_user_account_body_weight {
  value: number;
  label: string;
}

export interface GetUserAccountBody_user_account_body_upper_size {
  value: number;
  label: string;
}

export interface GetUserAccountBody_user_account_body_bottom_size {
  value: number;
  label: string;
}

export interface GetUserAccountBody_user_account_body_shoe_size {
  value: number;
  label: string;
}

export interface GetUserAccountBody_user_account_body_foot_shape {
  value: string;
  label: string;
}

export interface GetUserAccountBody_user_account_body_skin_type {
  value: string;
  label: string;
}

export interface GetUserAccountBody_user_account_body_skin_tone {
  value: string;
  label: string;
}

export interface GetUserAccountBody_user_account_body_skin_tone_base {
  value: string;
  label: string;
}

export interface GetUserAccountBody_user_account_body_skin_concern {
  value: string;
  label: string;
}

export interface GetUserAccountBody_user_account_body {
  height: GetUserAccountBody_user_account_body_height;
  weight: GetUserAccountBody_user_account_body_weight;
  upper_size: GetUserAccountBody_user_account_body_upper_size;
  bottom_size: GetUserAccountBody_user_account_body_bottom_size;
  shoe_size: GetUserAccountBody_user_account_body_shoe_size;
  foot_shape: GetUserAccountBody_user_account_body_foot_shape[] | null;
  skin_type: GetUserAccountBody_user_account_body_skin_type | null;
  skin_tone: GetUserAccountBody_user_account_body_skin_tone | null;
  skin_tone_base: GetUserAccountBody_user_account_body_skin_tone_base | null;
  skin_concern: GetUserAccountBody_user_account_body_skin_concern[] | null;
}

export interface GetUserAccountBody_user_account {
  /**
   * 사용자 이름
   */
  full_name: string | null;
  /**
   * 사용자 계정 신체 정보
   */
  body: GetUserAccountBody_user_account_body | null;
}

export interface GetUserAccountBody {
  user_account: GetUserAccountBody_user_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserAccountBody
// ====================================================

export interface SetUserAccountBody {
  /**
   * 사용자 계정 신체정보를 신규생성하거나 수정한다.
   */
  setUserAccountBody: boolean;
}

export interface SetUserAccountBodyVariables {
  input: SetUserAccountBodyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReportProductReviewAbuse
// ====================================================

export interface ReportProductReviewAbuse {
  /**
   * 상품리뷰를 신고한다
   * [error_code]
   * - product_review_not_found: 상품리뷰를 찾을 수 없는 경우
   * - product_review_abuse_report_reported_reason_detail_required: 신고 상세사유를 입력해야 하는데 입력하지 않은 경우
   */
  reportProductReviewAbuse: boolean;
}

export interface ReportProductReviewAbuseVariables {
  input: ReportProductReviewAbuseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReportProductReviewUserReply
// ====================================================

export interface ReportProductReviewUserReply {
  /**
   * 상품리뷰 유저 댓글을 신고한다
   */
  reportProductReviewUserReply: boolean;
}

export interface ReportProductReviewUserReplyVariables {
  input: ReportProductReviewUserReplyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAgeRecommendedKeywordInfo
// ====================================================

export interface GetAgeRecommendedKeywordInfo_age_recommended_keyword_info_age_recommended_keyword_group_list {
  age: string;
  recommended_keyword_list: (string | null)[];
}

export interface GetAgeRecommendedKeywordInfo_age_recommended_keyword_info {
  title: string | null;
  age_recommended_keyword_group_list: (GetAgeRecommendedKeywordInfo_age_recommended_keyword_info_age_recommended_keyword_group_list | null)[];
}

export interface GetAgeRecommendedKeywordInfo {
  age_recommended_keyword_info: GetAgeRecommendedKeywordInfo_age_recommended_keyword_info;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecommendedSearchKeywordList
// ====================================================

export interface GetRecommendedSearchKeywordList_recommended_search_keyword_list_item_list {
  /**
   * 검색어 타입
   */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text: string | null;
  /**
   * 추천 검색어
   */
  keyword: string;
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: string;
}

export interface GetRecommendedSearchKeywordList_recommended_search_keyword_list {
  item_list: GetRecommendedSearchKeywordList_recommended_search_keyword_list_item_list[];
}

export interface GetRecommendedSearchKeywordList {
  /**
   * 추천 검색어 목록을 Shop > SearchedKeywordHistory > TagShop > Category > Event > UserQuery 순서로 반환한다.
   */
  recommended_search_keyword_list: GetRecommendedSearchKeywordList_recommended_search_keyword_list;
}

export interface GetRecommendedSearchKeywordListVariables {
  keyword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchGoodsSuggestion
// ====================================================

export interface GetSearchGoodsSuggestion_search_popular_keyword {
  /**
   * 최근 검색 키워드 타이틀
   */
  recent_searches_title: string;
  /**
   * 구(舊) 인기검색어
   */
  keywords_title: string;
  /**
   * 구(舊) 인기검색어
   */
  keywords: string[];
}

export interface GetSearchGoodsSuggestion {
  /**
   * 검색 페이지 인기 키워드 정보
   */
  search_popular_keyword: GetSearchGoodsSuggestion_search_popular_keyword;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrencyMeta
// ====================================================

export interface GetCurrencyMeta_currency_meta {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: PaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 원화에 곱하는 값 (원화 x converted_rate = display 통화)
   */
  converted_rate: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface GetCurrencyMeta {
  /**
   * 조건에 맞는 다통화 메타데이터 정보를 반환한다.
   * display_currency_type: 통화 표시 타입
   */
  currency_meta: GetCurrencyMeta_currency_meta;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrencyMetaOfOrder
// ====================================================

export interface GetCurrencyMetaOfOrder_currency_meta_of_order {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: PaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 원화에 곱하는 값 (원화 x converted_rate = display 통화)
   */
  converted_rate: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface GetCurrencyMetaOfOrder {
  /**
   * 주문 시점의 환율 정보를 조회한다.
   */
  currency_meta_of_order: GetCurrencyMetaOfOrder_currency_meta_of_order | null;
}

export interface GetCurrencyMetaOfOrderVariables {
  order_number: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserLocale
// ====================================================

export interface GetUserLocale_user_locale_country {
  id: string;
  type: LocaleCodeType;
  code: string;
  label: string;
}

export interface GetUserLocale_user_locale_currency {
  id: string;
  type: LocaleCodeType;
  code: string;
  label: string;
}

export interface GetUserLocale_user_locale_language {
  id: string;
  type: LocaleCodeType;
  code: string;
  label: string;
}

export interface GetUserLocale_user_locale {
  country_id: string;
  currency_id: string;
  language_id: string;
  country: GetUserLocale_user_locale_country | null;
  currency: GetUserLocale_user_locale_currency | null;
  language: GetUserLocale_user_locale_language | null;
}

export interface GetUserLocale {
  /**
   * 사용자 로케일 정보 반환 (기본값을 입력하면 값이 없을 시 기본값으로 초기화 후 반환한다)
   */
  user_locale: GetUserLocale_user_locale | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserLocale
// ====================================================

export interface UpdateUserLocale_updateUserLocale {
  country_id: string;
  currency_id: string;
  language_id: string;
}

export interface UpdateUserLocale {
  /**
   * 사용자 로케일 정보를 업데이트한다
   * [error_code]
   * - invalid_params: 국가/언어/통화 코드가 모두 없는 경우
   * - user_locale_not_found: 이미 생성된 사용자 로케일 정보가 없는 경우
   */
  updateUserLocale: UpdateUserLocale_updateUserLocale;
}

export interface UpdateUserLocaleVariables {
  input: UpdateUserLocaleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserLocaleCodeList
// ====================================================

export interface GetUserLocaleCodeList_locale_code_list_item_list {
  id: string;
  type: LocaleCodeType;
  code: string;
  label: string;
}

export interface GetUserLocaleCodeList_locale_code_list {
  item_list: GetUserLocaleCodeList_locale_code_list_item_list[];
  total_count: number;
}

export interface GetUserLocaleCodeList {
  /**
   * locale code 목록 반환
   */
  locale_code_list: GetUserLocaleCodeList_locale_code_list;
}

export interface GetUserLocaleCodeListVariables {
  input?: LocaleCodeType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestUserAccountAuthentication
// ====================================================

export interface RequestUserAccountAuthentication_requestUserAccountAuthentication {
  /**
   * 인증 정보
   */
  tr_cert: string;
}

export interface RequestUserAccountAuthentication {
  /**
   * 본인 인증 과정에 필요한 값을 요청한다
   * [error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - invalid_authentication_purpose_type: purpose_type을 잘 못 입력한 경우
   */
  requestUserAccountAuthentication: RequestUserAccountAuthentication_requestUserAccountAuthentication;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GetSimpleProfileFragment
// ====================================================

export interface GetSimpleProfileFragment {
  /**
   * 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다
   */
  uuid: string;
  /**
   * 사용자 이메일
   */
  email: string;
  /**
   * 사용자 이름
   */
  full_name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserShippingAddressBookFragment
// ====================================================

export interface UserShippingAddressBookFragment {
  /**
   * 기본 키
   */
  id: string;
  /**
   * 국가 정보 code
   */
  country: CountryType;
  /**
   * 배송지 명
   */
  address_name: string;
  /**
   * 수령인(이름)
   */
  first_name: string;
  /**
   * 수령인(성)
   */
  last_name: string;
  /**
   * 국가번호
   */
  country_number: string;
  /**
   * 연락처
   */
  contract_number: string;
  /**
   * 우편번호
   */
  post_code: string;
  /**
   * 주소지
   */
  address: string;
  /**
   * 상세주소지
   */
  detail_address: string | null;
  /**
   * 지역
   */
  state: string | null;
  /**
   * 도시
   */
  city: string | null;
  /**
   * 디폴트 셋팅 여부
   */
  is_default: boolean | null;
  /**
   * 공동현관 비밀번호
   */
  entrance_password: string | null;
  /**
   * 공동현관 출입 유형(비밀번호입력/자유출입)
   */
  entrance_type: EntranceType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxCommonTextPart
// ====================================================

export interface UxCommonTextPart_color {
  normal: string | null;
  dark: string | null;
}

export interface UxCommonTextPart_html {
  normal: string | null;
  dark: string | null;
}

export interface UxCommonTextPart {
  text: string;
  color: UxCommonTextPart_color | null;
  html: UxCommonTextPart_html | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxGoodsCardItemPart
// ====================================================

export interface UxGoodsCardItemPart_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface UxGoodsCardItemPart_one_day_delivery_color {
  normal: string | null;
  dark: string | null;
}

export interface UxGoodsCardItemPart_one_day_delivery_html {
  normal: string | null;
  dark: string | null;
}

export interface UxGoodsCardItemPart_one_day_delivery {
  text: string;
  color: UxGoodsCardItemPart_one_day_delivery_color | null;
  html: UxGoodsCardItemPart_one_day_delivery_html | null;
}

export interface UxGoodsCardItemPart_final_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface UxGoodsCardItemPart_max_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface UxGoodsCardItemPart {
  browsing_type: UxBrowsingType;
  position: number;
  type: UxComponentType;
  image_url: string | null;
  webp_image_url: string | null;
  jpeg_image_url: string | null;
  video_url: string | null;
  log: string | null;
  image_ratio: number | null;
  aid: string | null;
  uuid: string | null;
  product_url: string | null;
  shop_id: string;
  shop_product_no: string | null;
  shop_name: string | null;
  title: string | null;
  discount_rate: number | null;
  discount_info: UxGoodsCardItemPart_discount_info | null;
  column_count: number | null;
  catalog_product_id: string | null;
  goods_id: string | null;
  one_day_delivery: UxGoodsCardItemPart_one_day_delivery | null;
  has_coupon: boolean;
  is_zpay_discount: boolean;
  price: number | null;
  final_price: number;
  final_price_with_currency: UxGoodsCardItemPart_final_price_with_currency;
  max_price: number;
  max_price_with_currency: UxGoodsCardItemPart_max_price_with_currency;
  is_zonly: boolean;
  is_brand: boolean;
  free_shipping: boolean | null;
  zpay: boolean | null;
  ranking: number | null;
  sellable_status: UxSellableStatus;
  review_score: number | null;
  display_review_count: string | null;
  is_ad: boolean;
  is_exclusive: boolean;
  similar_search: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UxComponentPart
// ====================================================

export interface UxComponentPart_UxImageBannerGroup {
  __typename: "UxImageBannerGroup" | "UxHybridPromotion" | "UxShopGroup" | "UxImage" | "UxShopExpandableList" | "UxTextTitle" | "UxImageAndTextTitle" | "UxGoodsCarousel" | "UxButton" | "UxLine" | "UxLineWithMargin" | "UxGoodsCardList" | "UxSelectionAndSorting" | "UxSelectionAndCheckBoxAndSorting" | "UxTextAndSorting" | "UxText" | "UxSorting" | "UxSegmentTab" | "UxClpSegmentTab" | "UxCategory" | "UxFullWidthImageBannerGroup" | "UxBandBanner" | "UxGoodsGridGroup" | "UxGoodsGroup" | "UxProductReviewSummaryGroup" | "UxSearchKeywordGuide" | "UxProductListGridGroup" | "UxChipButtonAndProductListGridGroup" | "UxChipButtonGridGroup" | "UxBrandThemeContent" | "UxWebPage" | "UxShopRankingCardItem" | "UxMultilineRankingCarousel" | "UxCheckButtonAndSorting" | "UxClpCategoryList" | "UxQuickMenu" | "UxEntryBanner" | "UxNoResults" | "UxAdDisplayClpBanner" | "UxAdDisplayLargeBanner" | "UxAdDisplayMidBanner" | "UxAdDisplaySmallBanner" | "UxAdDisplayXLargeBanner" | "UxAdDisplayXMidBanner" | "UxAdDisplayBannerGroup" | "UxTextAndMoreButton" | "UxSearchedShopCarousel" | "UxGoodsFilterList" | "UxDdpTemplate" | "UxSingleBanner" | "UxSearchResultHeader" | "UxSearchResultQuickFilterList" | "UxBrandRecommendGroup" | "UxBannerItem" | "UxThemeComponentCarousel" | "UxBrandChipProductListGroup" | "UxErrorComponent" | "UxThemeRanking" | "UxTimeDealCard" | "UxAdBannerCard" | "UxBenefitsCard" | "ProductCardUxComponent" | "UxPromotionFloatingBanner" | "UxPromotionTC";
}

export interface UxComponentPart_UxGoodsCardItem_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface UxComponentPart_UxGoodsCardItem_one_day_delivery_color {
  normal: string | null;
  dark: string | null;
}

export interface UxComponentPart_UxGoodsCardItem_one_day_delivery_html {
  normal: string | null;
  dark: string | null;
}

export interface UxComponentPart_UxGoodsCardItem_one_day_delivery {
  text: string;
  color: UxComponentPart_UxGoodsCardItem_one_day_delivery_color | null;
  html: UxComponentPart_UxGoodsCardItem_one_day_delivery_html | null;
}

export interface UxComponentPart_UxGoodsCardItem_final_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface UxComponentPart_UxGoodsCardItem_max_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface UxComponentPart_UxGoodsCardItem {
  __typename: "UxGoodsCardItem";
  browsing_type: UxBrowsingType;
  position: number;
  type: UxComponentType;
  image_url: string | null;
  webp_image_url: string | null;
  jpeg_image_url: string | null;
  video_url: string | null;
  log: string | null;
  image_ratio: number | null;
  aid: string | null;
  uuid: string | null;
  product_url: string | null;
  shop_id: string;
  shop_product_no: string | null;
  shop_name: string | null;
  title: string | null;
  discount_rate: number | null;
  discount_info: UxComponentPart_UxGoodsCardItem_discount_info | null;
  column_count: number | null;
  catalog_product_id: string | null;
  goods_id: string | null;
  one_day_delivery: UxComponentPart_UxGoodsCardItem_one_day_delivery | null;
  has_coupon: boolean;
  is_zpay_discount: boolean;
  price: number | null;
  final_price: number;
  final_price_with_currency: UxComponentPart_UxGoodsCardItem_final_price_with_currency;
  max_price: number;
  max_price_with_currency: UxComponentPart_UxGoodsCardItem_max_price_with_currency;
  is_zonly: boolean;
  is_brand: boolean;
  free_shipping: boolean | null;
  zpay: boolean | null;
  ranking: number | null;
  sellable_status: UxSellableStatus;
  review_score: number | null;
  display_review_count: string | null;
  is_ad: boolean;
  is_exclusive: boolean;
  similar_search: boolean | null;
}

export type UxComponentPart = UxComponentPart_UxImageBannerGroup | UxComponentPart_UxGoodsCardItem;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoPart
// ====================================================

export interface PageInfoPart_ui_item_list_UxImageBannerGroup {
  __typename: "UxImageBannerGroup" | "UxHybridPromotion" | "UxShopGroup" | "UxImage" | "UxShopExpandableList" | "UxTextTitle" | "UxImageAndTextTitle" | "UxGoodsCarousel" | "UxButton" | "UxLine" | "UxLineWithMargin" | "UxGoodsCardList" | "UxSelectionAndSorting" | "UxSelectionAndCheckBoxAndSorting" | "UxTextAndSorting" | "UxText" | "UxSorting" | "UxSegmentTab" | "UxClpSegmentTab" | "UxCategory" | "UxFullWidthImageBannerGroup" | "UxBandBanner" | "UxGoodsGridGroup" | "UxGoodsGroup" | "UxProductReviewSummaryGroup" | "UxSearchKeywordGuide" | "UxProductListGridGroup" | "UxChipButtonAndProductListGridGroup" | "UxChipButtonGridGroup" | "UxBrandThemeContent" | "UxWebPage" | "UxShopRankingCardItem" | "UxMultilineRankingCarousel" | "UxCheckButtonAndSorting" | "UxClpCategoryList" | "UxQuickMenu" | "UxEntryBanner" | "UxNoResults" | "UxAdDisplayClpBanner" | "UxAdDisplayLargeBanner" | "UxAdDisplayMidBanner" | "UxAdDisplaySmallBanner" | "UxAdDisplayXLargeBanner" | "UxAdDisplayXMidBanner" | "UxAdDisplayBannerGroup" | "UxTextAndMoreButton" | "UxSearchedShopCarousel" | "UxGoodsFilterList" | "UxDdpTemplate" | "UxSingleBanner" | "UxSearchResultHeader" | "UxSearchResultQuickFilterList" | "UxBrandRecommendGroup" | "UxBannerItem" | "UxThemeComponentCarousel" | "UxBrandChipProductListGroup" | "UxErrorComponent" | "UxThemeRanking" | "UxTimeDealCard" | "UxAdBannerCard" | "UxBenefitsCard" | "ProductCardUxComponent" | "UxPromotionFloatingBanner" | "UxPromotionTC";
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery_color {
  normal: string | null;
  dark: string | null;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery_html {
  normal: string | null;
  dark: string | null;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery {
  text: string;
  color: PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery_color | null;
  html: PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery_html | null;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_final_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem_max_price_with_currency {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

export interface PageInfoPart_ui_item_list_UxGoodsCardItem {
  __typename: "UxGoodsCardItem";
  browsing_type: UxBrowsingType;
  position: number;
  type: UxComponentType;
  image_url: string | null;
  webp_image_url: string | null;
  jpeg_image_url: string | null;
  video_url: string | null;
  log: string | null;
  image_ratio: number | null;
  aid: string | null;
  uuid: string | null;
  product_url: string | null;
  shop_id: string;
  shop_product_no: string | null;
  shop_name: string | null;
  title: string | null;
  discount_rate: number | null;
  discount_info: PageInfoPart_ui_item_list_UxGoodsCardItem_discount_info | null;
  column_count: number | null;
  catalog_product_id: string | null;
  goods_id: string | null;
  one_day_delivery: PageInfoPart_ui_item_list_UxGoodsCardItem_one_day_delivery | null;
  has_coupon: boolean;
  is_zpay_discount: boolean;
  price: number | null;
  final_price: number;
  final_price_with_currency: PageInfoPart_ui_item_list_UxGoodsCardItem_final_price_with_currency;
  max_price: number;
  max_price_with_currency: PageInfoPart_ui_item_list_UxGoodsCardItem_max_price_with_currency;
  is_zonly: boolean;
  is_brand: boolean;
  free_shipping: boolean | null;
  zpay: boolean | null;
  ranking: number | null;
  sellable_status: UxSellableStatus;
  review_score: number | null;
  display_review_count: string | null;
  is_ad: boolean;
  is_exclusive: boolean;
  similar_search: boolean | null;
}

export type PageInfoPart_ui_item_list = PageInfoPart_ui_item_list_UxImageBannerGroup | PageInfoPart_ui_item_list_UxGoodsCardItem;

export interface PageInfoPart {
  page_name: string | null;
  has_next: boolean | null;
  end_cursor: string | null;
  type: string;
  ui_item_list: PageInfoPart_ui_item_list[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PDPGoodsGroup
// ====================================================

export interface PDPGoodsGroup_main_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface PDPGoodsGroup_sub_title {
  /**
   * 텍스트
   */
  text: string;
}

export interface PDPGoodsGroup_more_button {
  /**
   * 텍스트
   */
  text: string;
  /**
   * 딥링크 URL
   */
  link_url: string;
}

export interface PDPGoodsGroup_goods_carousel_component_list_discount_info {
  image_url: string | null;
  title: string | null;
  color: string | null;
}

export interface PDPGoodsGroup_goods_carousel_component_list_badge_list {
  image_url: string;
  dark_image_url: string | null;
}

export interface PDPGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface PDPGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface PDPGoodsGroup_goods_carousel_component_list_brand_name_badge_list {
  image_url: string;
  dark_image_url: string | null;
  small_image_url: string | null;
  small_dark_image_url: string | null;
}

export interface PDPGoodsGroup_goods_carousel_component_list_managed_category_list {
  id: string;
  /**
   * category DB의 category id
   */
  category_id: number;
  /**
   * 카테고리 이름
   */
  value: string;
}

export interface PDPGoodsGroup_goods_carousel_component_list {
  /**
   * 광고 뱃지 여부
   */
  is_ad: boolean;
  /**
   * 컴포넌트 type
   */
  type: PdpComponentType;
  /**
   * 쇼핑몰 id
   */
  shop_id: string;
  /**
   * 쇼핑몰 이름
   */
  shop_name: string | null;
  /**
   * 쇼핑몰에서 부여한 고유 product_no
   */
  shop_product_no: string | null;
  position: number;
  /**
   * 원본 image_url
   */
  image_url: string | null;
  /**
   * 썸네일화 webp_image_url
   */
  webp_image_url: string | null;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid: string | null;
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge: boolean | null;
  /**
   * 상품 클릭시 이동할 url 주소
   */
  product_url: string | null;
  /**
   * 상품 이름
   */
  title: string | null;
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price: number | null;
  /**
   * 상품의 할인율
   */
  discount_rate: number | null;
  /**
   * 무료배송 여부
   */
  free_shipping: boolean | null;
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id: string | null;
  /**
   * zpay 상품 여부
   */
  zpay: boolean | null;
  /**
   * 상품이 한줄에 몇개 표기할지
   */
  column_count: number | null;
  /**
   * 상품 카드에 숫자를 표기하기 위한 필드
   */
  ranking: number | null;
  /**
   * 할인가 표기 정보
   */
  discount_info: PDPGoodsGroup_goods_carousel_component_list_discount_info | null;
  /**
   * 모든 할인이 적용된 최종 가격
   */
  final_price: number;
  /**
   * 연동된 가격중 가장 높은 가격
   */
  max_price: number;
  /**
   * catalog_product_id
   */
  catalog_product_id: string | null;
  /**
   * 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부
   */
  browsing_type: PdpBrowsingType;
  /**
   * log
   */
  log: string | null;
  /**
   * 광고 id
   */
  aid: string | null;
  /**
   * 이미지 비율
   */
  image_ratio: number | null;
  /**
   * 상품의 리뷰 갯수
   */
  review_count: number | null;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: boolean;
  /**
   * zonly 상품 여부
   */
  is_zonly: boolean;
  /**
   * 브랜드 상품 여부
   */
  is_brand: boolean;
  /**
   * Catalog 상품의 상태 (판매중, 품절)
   */
  sales_status: PdpSearchedProductSalesStatus | null;
  /**
   * 상품의 리뷰 갯수 (#,### 형태)
   */
  display_review_count: string | null;
  /**
   * 상품의 리뷰 점수
   */
  review_score: number | null;
  /**
   * 뱃지 리스트
   */
  badge_list: PDPGoodsGroup_goods_carousel_component_list_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 상단 뱃지 리스트
   */
  thumbnail_nudge_badge_list: PDPGoodsGroup_goods_carousel_component_list_thumbnail_nudge_badge_list[] | null;
  /**
   * PLP 상품카드 이미지 좌 하단 뱃지 리스트
   */
  thumbnail_emblem_badge_list: PDPGoodsGroup_goods_carousel_component_list_thumbnail_emblem_badge_list[] | null;
  /**
   * 브랜드 네임 우측 뱃지 리스트
   */
  brand_name_badge_list: PDPGoodsGroup_goods_carousel_component_list_brand_name_badge_list[] | null;
  /**
   * 관리 카테고리 리스트
   */
  managed_category_list: PDPGoodsGroup_goods_carousel_component_list_managed_category_list[] | null;
}

export interface PDPGoodsGroup_goods_carousel {
  /**
   * 상품 카드 컴포넌트 리스트
   */
  component_list: PDPGoodsGroup_goods_carousel_component_list[];
}

export interface PDPGoodsGroup {
  position: number;
  type: PdpComponentType;
  /**
   * 사용 목적 타입
   */
  group_type: string;
  /**
   * 광고 캐러셀 여부
   */
  is_ad: boolean;
  /**
   * 메인 타이틀
   */
  main_title: PDPGoodsGroup_main_title;
  /**
   * 서브 타이틀
   */
  sub_title: PDPGoodsGroup_sub_title | null;
  /**
   * 더보기 버튼
   */
  more_button: PDPGoodsGroup_more_button | null;
  /**
   * 상품 캐로셀
   */
  goods_carousel: PDPGoodsGroup_goods_carousel;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAccountBodyValue
// ====================================================

export interface UserAccountBodyValue {
  value: number;
  label: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAccountBodyStringValue
// ====================================================

export interface UserAccountBodyStringValue {
  value: string;
  label: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrencyMeta
// ====================================================

export interface CurrencyMeta {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: PaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 원화에 곱하는 값 (원화 x converted_rate = display 통화)
   */
  converted_rate: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 브라우저 타입
 */
export enum BrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

/**
 * 문의 상품 유형
 */
export enum CatalogQuestionProductType {
  GENERAL = "GENERAL",
  ZONLY = "ZONLY",
}

/**
 * 문의 유형
 */
export enum CatalogQuestionType {
  OTHER = "OTHER",
  PRODUCT = "PRODUCT",
  SHIPPING = "SHIPPING",
  SIZE = "SIZE",
  STOCK = "STOCK",
}

export enum CountryType {
  CA = "CA",
  JP = "JP",
  KR = "KR",
  US = "US",
}

/**
 * 공동현관 출입 유형
 */
export enum EntranceType {
  NO_PASSWORD = "NO_PASSWORD",
  PASSWORD = "PASSWORD",
}

/**
 * Locale code type
 */
export enum LocaleCodeType {
  COUNTRY = "COUNTRY",
  CURRENCY = "CURRENCY",
  LANGUAGE = "LANGUAGE",
}

/**
 * 주문 아이템의 상태
 */
export enum OrderItemStatus {
  AWAITING_SHIPMENT = "AWAITING_SHIPMENT",
  BEFORE_TRANSFER = "BEFORE_TRANSFER",
  CANCELLED = "CANCELLED",
  CANCELLED_AFTER_CONFIRMED = "CANCELLED_AFTER_CONFIRMED",
  CANCELLED_BEFORE_TRANSFER = "CANCELLED_BEFORE_TRANSFER",
  CANCELLING = "CANCELLING",
  CANCEL_DEFERRED = "CANCEL_DEFERRED",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  CONFIRMED = "CONFIRMED",
  DOMESTIC_IN_TRANSIT = "DOMESTIC_IN_TRANSIT",
  EXCHANGED = "EXCHANGED",
  EXCHANGE_COLLECTED = "EXCHANGE_COLLECTED",
  EXCHANGE_COLLECTING = "EXCHANGE_COLLECTING",
  EXCHANGE_DEFERRED = "EXCHANGE_DEFERRED",
  EXCHANGE_REQUESTED = "EXCHANGE_REQUESTED",
  INTERNATIONAL_AWAITING_SHIPMENT = "INTERNATIONAL_AWAITING_SHIPMENT",
  INTERNATIONAL_IN_TRANSIT = "INTERNATIONAL_IN_TRANSIT",
  IN_TRANSIT = "IN_TRANSIT",
  NEW_ORDER = "NEW_ORDER",
  RETURNED = "RETURNED",
  RETURN_COLLECTED = "RETURN_COLLECTED",
  RETURN_COLLECTING = "RETURN_COLLECTING",
  RETURN_DEFERRED = "RETURN_DEFERRED",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  SHIPMENT_PROCESS_REQUESTED = "SHIPMENT_PROCESS_REQUESTED",
  SHIPPED = "SHIPPED",
  SHIPPING_DEFERRED = "SHIPPING_DEFERRED",
}

/**
 * 환율 enum
 */
export enum PaymentCurrency {
  AUD = "AUD",
  CAD = "CAD",
  CNY = "CNY",
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  KRW = "KRW",
  NZD = "NZD",
  USD = "USD",
}

/**
 * 결제 수단
 */
export enum PaymentMethod {
  BANK = "BANK",
  CARD = "CARD",
  CONVENIENCE_STORE = "CONVENIENCE_STORE",
  CONVENIENCE_STORE_JP = "CONVENIENCE_STORE_JP",
  GLOBAL_CARD = "GLOBAL_CARD",
  KAKAOPAY = "KAKAOPAY",
  PHONE = "PHONE",
  POINT = "POINT",
  SIMPLE_BANK = "SIMPLE_BANK",
  SIMPLE_CARD = "SIMPLE_CARD",
  UNDEFINED_PAYMENT_METHOD = "UNDEFINED_PAYMENT_METHOD",
  VBANK = "VBANK",
}

export enum PaymentNotificationType {
  EVENT = "EVENT",
  GUIDE = "GUIDE",
  NOTICE = "NOTICE",
}

export enum PdpBrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

export enum PdpCatalogProductImageType {
  CONTENT = "CONTENT",
  MAIN = "MAIN",
  SUB = "SUB",
}

export enum PdpCatalogProductType {
  GENERAL = "GENERAL",
  ZONLY = "ZONLY",
}

export enum PdpComponentType {
  UX_AD_DISPLAY_BANNER_GROUP = "UX_AD_DISPLAY_BANNER_GROUP",
  UX_AD_DISPLAY_LARGE_BANNER = "UX_AD_DISPLAY_LARGE_BANNER",
  UX_AD_DISPLAY_MID_BANNER = "UX_AD_DISPLAY_MID_BANNER",
  UX_AD_DISPLAY_SMALL_BANNER = "UX_AD_DISPLAY_SMALL_BANNER",
  UX_AD_DISPLAY_X_LARGE_BANNER = "UX_AD_DISPLAY_X_LARGE_BANNER",
  UX_AD_DISPLAY_X_MID_BANNER = "UX_AD_DISPLAY_X_MID_BANNER",
  UX_BANNER_ITEM = "UX_BANNER_ITEM",
  UX_BANNER_LIST = "UX_BANNER_LIST",
  UX_BRAND_THEME_CONTENT = "UX_BRAND_THEME_CONTENT",
  UX_BUTTON = "UX_BUTTON",
  UX_CATEGORY = "UX_CATEGORY",
  UX_CHECK_BUTTON_AND_SORTING = "UX_CHECK_BUTTON_AND_SORTING",
  UX_CLP_CATEGORY = "UX_CLP_CATEGORY",
  UX_ENTRY_BANNER = "UX_ENTRY_BANNER",
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = "UX_FULL_WIDTH_IMAGE_BANNER_GROUP",
  UX_GOODS_CARD_ITEM = "UX_GOODS_CARD_ITEM",
  UX_GOODS_CARD_LIST = "UX_GOODS_CARD_LIST",
  UX_GOODS_CAROUSEL = "UX_GOODS_CAROUSEL",
  UX_GOODS_FILTER_LIST = "UX_GOODS_FILTER_LIST",
  UX_GOODS_GROUP = "UX_GOODS_GROUP",
  UX_IMAGE = "UX_IMAGE",
  UX_IMAGE_AND_TEXT_TITLE = "UX_IMAGE_AND_TEXT_TITLE",
  UX_IMAGE_BANNER_GROUP = "UX_IMAGE_BANNER_GROUP",
  UX_IMAGE_GOODS_CARD_ITEM = "UX_IMAGE_GOODS_CARD_ITEM",
  UX_LINE = "UX_LINE",
  UX_LINE_WITH_MARGIN = "UX_LINE_WITH_MARGIN",
  UX_MULTILINE_RANKING_CAROUSEL = "UX_MULTILINE_RANKING_CAROUSEL",
  UX_NO_RESULTS = "UX_NO_RESULTS",
  UX_PDP_RECOMMEND_CARD_GROUP = "UX_PDP_RECOMMEND_CARD_GROUP",
  UX_PDP_RECOMMEND_GROUP = "UX_PDP_RECOMMEND_GROUP",
  UX_QUICK_MENU = "UX_QUICK_MENU",
  UX_SEARCHED_SHOP_CAROUSEL = "UX_SEARCHED_SHOP_CAROUSEL",
  UX_SEGMENT_TAB = "UX_SEGMENT_TAB",
  UX_SELECTION_AND_SORTING = "UX_SELECTION_AND_SORTING",
  UX_SHOP_EXPANDABLE_LIST = "UX_SHOP_EXPANDABLE_LIST",
  UX_SHOP_GROUP = "UX_SHOP_GROUP",
  UX_SHOP_RANKING_CARD_ITEM = "UX_SHOP_RANKING_CARD_ITEM",
  UX_SMALL_TEXT_TITLE = "UX_SMALL_TEXT_TITLE",
  UX_SORTING = "UX_SORTING",
  UX_TEXT = "UX_TEXT",
  UX_TEXT_AND_MORE_BUTTON = "UX_TEXT_AND_MORE_BUTTON",
  UX_TEXT_AND_SORTING = "UX_TEXT_AND_SORTING",
  UX_TEXT_TITLE = "UX_TEXT_TITLE",
  UX_WEB_PAGE = "UX_WEB_PAGE",
  UX_ZONLY_ENTRY_BANNER = "UX_ZONLY_ENTRY_BANNER",
  UX_ZONLY_ENTRY_CARD = "UX_ZONLY_ENTRY_CARD",
  UX_ZONLY_ENTRY_GROUP = "UX_ZONLY_ENTRY_GROUP",
  UX_ZONLY_ENTRY_INFO = "UX_ZONLY_ENTRY_INFO",
}

export enum PdpProductSizeType {
  NONE = "NONE",
  OPTION = "OPTION",
  PURCHASE = "PURCHASE",
  RECOMMENDATION = "RECOMMENDATION",
}

export enum PdpSearchedProductSalesStatus {
  CLOSED = "CLOSED",
  ON_SALE = "ON_SALE",
  PREPARING = "PREPARING",
  SOLD_OUT = "SOLD_OUT",
  SUSPENDED = "SUSPENDED",
}

export enum PlusFriendsType {
  ADDED = "ADDED",
  BLOCKED = "BLOCKED",
  NONE = "NONE",
}

/**
 * 상품리뷰 신고 사유 카테고리
 */
export enum ProductReviewAbuseReportReasonCategory {
  COPYRIGHT_VIOLATION = "COPYRIGHT_VIOLATION",
  ETC = "ETC",
  INSULTING_OR_OBSCENE_CONTENTS = "INSULTING_OR_OBSCENE_CONTENTS",
  NOT_RELATED_TO_SHOP_OR_PRODUCT = "NOT_RELATED_TO_SHOP_OR_PRODUCT",
  PERSONAL_CONTENTS = "PERSONAL_CONTENTS",
  PROMOTIONAL_CONTENTS = "PROMOTIONAL_CONTENTS",
}

/**
 * 상품 리뷰 첨부파일 상태
 */
export enum ProductReviewAttachmentStatus {
  DELETED_BY_ADMIN = "DELETED_BY_ADMIN",
  EXCLUDED_BY_USER = "EXCLUDED_BY_USER",
  NORMAL = "NORMAL",
}

/**
 * 상품리뷰 신고 사유 카테고리
 */
export enum ProductReviewReplyAbuseReportReasonCategory {
  ETC = "ETC",
  INSULTING_OR_OBSCENE_CONTENTS = "INSULTING_OR_OBSCENE_CONTENTS",
  NOT_RELATED_TO_SHOP_OR_PRODUCT = "NOT_RELATED_TO_SHOP_OR_PRODUCT",
  PERSONAL_CONTENTS = "PERSONAL_CONTENTS",
  PROMOTIONAL_CONTENTS = "PROMOTIONAL_CONTENTS",
}

/**
 * 상품 리뷰 유형
 */
export enum ProductReviewType {
  PHOTO = "PHOTO",
  QUICK = "QUICK",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

/**
 * 검색어 타입
 */
export enum RecommendedSearchKeywordType {
  BRAND = "BRAND",
  CATEGORY = "CATEGORY",
  CATEGORY_LANDING = "CATEGORY_LANDING",
  EVENT = "EVENT",
  PICK = "PICK",
  SEARCHED_KEYWORD_HISTORY = "SEARCHED_KEYWORD_HISTORY",
  SHOP = "SHOP",
  TAG_SHOP = "TAG_SHOP",
  USER_QUERY = "USER_QUERY",
}

/**
 * 업로드 링크 범주
 */
export enum UploadLinkCategory {
  ORDER_ITEM_INQUIRY = "ORDER_ITEM_INQUIRY",
  ORDER_ITEM_REQUEST = "ORDER_ITEM_REQUEST",
  PRODUCT_REVIEW = "PRODUCT_REVIEW",
}

/**
 * 사용자 계정 신체정보의 발 형태
 */
export enum UserAccountBodyFootShape {
  HIGH = "HIGH",
  LOW = "LOW",
  NARROW = "NARROW",
  NONE = "NONE",
  WIDE = "WIDE",
}

/**
 * 사용자 계정 신체정보의 피부 고민
 */
export enum UserAccountBodySkinConcern {
  ACNE = "ACNE",
  ACNE_SCARS = "ACNE_SCARS",
  BLACKHEAD = "BLACKHEAD",
  BLEMISHES = "BLEMISHES",
  DANDRUFF = "DANDRUFF",
  DARK_CIRCLES = "DARK_CIRCLES",
  ECZEMA = "ECZEMA",
  ELASTICITY = "ELASTICITY",
  EXCESS_SEBUM = "EXCESS_SEBUM",
  EXFOLIATION = "EXFOLIATION",
  HAIR_LOSS = "HAIR_LOSS",
  MELASMA = "MELASMA",
  NONE = "NONE",
  OIL_AND_WATER_BALANCE = "OIL_AND_WATER_BALANCE",
  REDNESS = "REDNESS",
  SENSITIVITY = "SENSITIVITY",
  WHITENING = "WHITENING",
  WRINKLES = "WRINKLES",
}

/**
 * 사용자 계정 신체정보의 피부 톤
 */
export enum UserAccountBodySkinTone {
  AUTUMN_COOL = "AUTUMN_COOL",
  AUTUMN_WARM = "AUTUMN_WARM",
  COOL = "COOL",
  NEUTRAL = "NEUTRAL",
  SPRING_WARM = "SPRING_WARM",
  SUMMER_COOL = "SUMMER_COOL",
  WARM = "WARM",
}

/**
 * 사용자 계정 신체정보의 피부 톤(베이스 컬러)
 */
export enum UserAccountBodySkinToneBase {
  FAIR = "FAIR",
  LIGHT_BEIGE = "LIGHT_BEIGE",
  MEDIUM_BEIGE = "MEDIUM_BEIGE",
  TAN = "TAN",
}

/**
 * 사용자 계정 신체정보의 피부 타입
 */
export enum UserAccountBodySkinType {
  ACNE_PRONE = "ACNE_PRONE",
  COMBINATION = "COMBINATION",
  DRY = "DRY",
  EXTREMELY_DRY = "EXTREMELY_DRY",
  NORMAL = "NORMAL",
  OILY = "OILY",
  OILY_BUT_DEHYDRATED = "OILY_BUT_DEHYDRATED",
  SENSITIVITY = "SENSITIVITY",
}

/**
 * 사용자 계정 최초 가입경로
 */
export enum UserAccountInitType {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GENERAL = "GENERAL",
  GOOGLE = "GOOGLE",
  KAKAO = "KAKAO",
}

export enum UxBrowsingType {
  DETAIL_BROWSER = "DETAIL_BROWSER",
  INTERNAL_BROWSER = "INTERNAL_BROWSER",
  NORMAL = "NORMAL",
  Z_BROWSER = "Z_BROWSER",
}

export enum UxComponentType {
  UX_AD_BANNER_CARD = "UX_AD_BANNER_CARD",
  UX_AD_DISPLAY_BANNER_GROUP = "UX_AD_DISPLAY_BANNER_GROUP",
  UX_AD_DISPLAY_CLP_BANNER = "UX_AD_DISPLAY_CLP_BANNER",
  UX_AD_DISPLAY_LARGE_BANNER = "UX_AD_DISPLAY_LARGE_BANNER",
  UX_AD_DISPLAY_MID_BANNER = "UX_AD_DISPLAY_MID_BANNER",
  UX_AD_DISPLAY_SMALL_BANNER = "UX_AD_DISPLAY_SMALL_BANNER",
  UX_AD_DISPLAY_X_LARGE_BANNER = "UX_AD_DISPLAY_X_LARGE_BANNER",
  UX_AD_DISPLAY_X_MID_BANNER = "UX_AD_DISPLAY_X_MID_BANNER",
  UX_BAND_BANNER = "UX_BAND_BANNER",
  UX_BANNER_ITEM = "UX_BANNER_ITEM",
  UX_BANNER_LIST = "UX_BANNER_LIST",
  UX_BENEFITS_CARD = "UX_BENEFITS_CARD",
  UX_BRAND_CHIP_PRODUCT_LIST_GROUP = "UX_BRAND_CHIP_PRODUCT_LIST_GROUP",
  UX_BRAND_RECOMMEND_GROUP = "UX_BRAND_RECOMMEND_GROUP",
  UX_BRAND_THEME_CONTENT = "UX_BRAND_THEME_CONTENT",
  UX_BUTTON = "UX_BUTTON",
  UX_CATEGORY = "UX_CATEGORY",
  UX_CHECKBUTTON_AND_SELECTION_AND_SORTING = "UX_CHECKBUTTON_AND_SELECTION_AND_SORTING",
  UX_CHECK_BUTTON_AND_SORTING = "UX_CHECK_BUTTON_AND_SORTING",
  UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP = "UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP",
  UX_CHIP_BUTTON_GRID_GROUP = "UX_CHIP_BUTTON_GRID_GROUP",
  UX_CLP_CATEGORY = "UX_CLP_CATEGORY",
  UX_CLP_SEGMENT_TAB = "UX_CLP_SEGMENT_TAB",
  UX_DDP_TEMPLATE = "UX_DDP_TEMPLATE",
  UX_ENTRY_BANNER = "UX_ENTRY_BANNER",
  UX_ERROR = "UX_ERROR",
  UX_FULL_WIDTH_GOODS_CARD_ITEM = "UX_FULL_WIDTH_GOODS_CARD_ITEM",
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = "UX_FULL_WIDTH_IMAGE_BANNER_GROUP",
  UX_GOODS_CARD_ITEM = "UX_GOODS_CARD_ITEM",
  UX_GOODS_CARD_LIST = "UX_GOODS_CARD_LIST",
  UX_GOODS_CAROUSEL = "UX_GOODS_CAROUSEL",
  UX_GOODS_FILTER_LIST = "UX_GOODS_FILTER_LIST",
  UX_GOODS_GRID_GROUP = "UX_GOODS_GRID_GROUP",
  UX_GOODS_GROUP = "UX_GOODS_GROUP",
  UX_HYBRID_PROMOTION = "UX_HYBRID_PROMOTION",
  UX_IMAGE = "UX_IMAGE",
  UX_IMAGE_AND_TEXT_TITLE = "UX_IMAGE_AND_TEXT_TITLE",
  UX_IMAGE_BANNER_GROUP = "UX_IMAGE_BANNER_GROUP",
  UX_IMAGE_GOODS_CARD_ITEM = "UX_IMAGE_GOODS_CARD_ITEM",
  UX_LINE = "UX_LINE",
  UX_LINE_WITH_MARGIN = "UX_LINE_WITH_MARGIN",
  UX_MULTILINE_RANKING_CAROUSEL = "UX_MULTILINE_RANKING_CAROUSEL",
  UX_NO_RESULTS = "UX_NO_RESULTS",
  UX_PRODUCT_LIST_GRID_GROUP = "UX_PRODUCT_LIST_GRID_GROUP",
  UX_PRODUCT_REVIEW_SUMMARY_GROUP = "UX_PRODUCT_REVIEW_SUMMARY_GROUP",
  UX_PROMOTION_FLOATING_BANNER = "UX_PROMOTION_FLOATING_BANNER",
  UX_PROMOTION_TC = "UX_PROMOTION_TC",
  UX_QUICK_MENU = "UX_QUICK_MENU",
  UX_SEARCHED_SHOP_CAROUSEL = "UX_SEARCHED_SHOP_CAROUSEL",
  UX_SEARCH_KEYWORD_GUIDE = "UX_SEARCH_KEYWORD_GUIDE",
  UX_SEARCH_RESULT_HEADER = "UX_SEARCH_RESULT_HEADER",
  UX_SEARCH_RESULT_QUICK_FILTER_LIST = "UX_SEARCH_RESULT_QUICK_FILTER_LIST",
  UX_SEGMENT_TAB = "UX_SEGMENT_TAB",
  UX_SELECTION_AND_SORTING = "UX_SELECTION_AND_SORTING",
  UX_SHOP_EXPANDABLE_LIST = "UX_SHOP_EXPANDABLE_LIST",
  UX_SHOP_GROUP = "UX_SHOP_GROUP",
  UX_SHOP_RANKING_CARD_ITEM = "UX_SHOP_RANKING_CARD_ITEM",
  UX_SINGLE_BANNER = "UX_SINGLE_BANNER",
  UX_SMALL_TEXT_TITLE = "UX_SMALL_TEXT_TITLE",
  UX_SORTING = "UX_SORTING",
  UX_TEXT = "UX_TEXT",
  UX_TEXT_AND_MORE_BUTTON = "UX_TEXT_AND_MORE_BUTTON",
  UX_TEXT_AND_SORTING = "UX_TEXT_AND_SORTING",
  UX_TEXT_TITLE = "UX_TEXT_TITLE",
  UX_THEME_CATEGORY = "UX_THEME_CATEGORY",
  UX_THEME_COMPONENT_CAROUSEL_GROUP = "UX_THEME_COMPONENT_CAROUSEL_GROUP",
  UX_TIME_DEAL_CARD = "UX_TIME_DEAL_CARD",
  UX_WEB_PAGE = "UX_WEB_PAGE",
  UX_ZONLY_ENTRY_BANNER = "UX_ZONLY_ENTRY_BANNER",
  UX_ZONLY_ENTRY_CARD = "UX_ZONLY_ENTRY_CARD",
  UX_ZONLY_ENTRY_GROUP = "UX_ZONLY_ENTRY_GROUP",
  UX_ZONLY_ENTRY_INFO = "UX_ZONLY_ENTRY_INFO",
}

export enum UxPaymentCurrency {
  CAD = "CAD",
  JPY = "JPY",
  KRW = "KRW",
  USD = "USD",
}

export enum UxSellableStatus {
  CLOSED = "CLOSED",
  NOT_SUPPORT_COUNTRY = "NOT_SUPPORT_COUNTRY",
  ON_SALE = "ON_SALE",
  SOLD_OUT = "SOLD_OUT",
}

export enum ZigzagNoticeOsType {
  ANDROID = "ANDROID",
  COMMON = "COMMON",
  IOS = "IOS",
  NONE = "NONE",
}

export interface CatalogQuestionInput {
  shop_id: string;
  product_id: string;
  contents: string;
  product_type: CatalogQuestionProductType;
  question_type: CatalogQuestionType;
}

/**
 * connectAppleWithAuth 의 입력
 */
export interface ConnectAppleWithAuthInput {
  email: string;
  password: string;
  code: string;
  bundle_id: string;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  disconnect_other_account?: boolean | null;
}

/**
 * connectFacebookWithAuth 의 입력
 */
export interface ConnectFacebookWithAuthInput {
  email: string;
  password: string;
  access_token: string;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  disconnect_other_account?: boolean | null;
}

/**
 * connectGoogleWithAuth 의 입력
 */
export interface ConnectGoogleWithAuthInput {
  email: string;
  password: string;
  token: string;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  disconnect_other_account?: boolean | null;
}

/**
 * connectKakaoWithAuth 의 입력
 */
export interface ConnectKakaoWithAuthInput {
  email: string;
  password: string;
  access_token: string;
  refresh_token: string;
  disconnect_other_account?: boolean | null;
}

export interface CreateUploadLinkInput {
  category: UploadLinkCategory;
  expire_seconds?: number | null;
}

/**
 * deleteUserAccount의 입력
 */
export interface DeleteUserAccountInput {
  reason: string;
}

export interface GoodsFilterOptionInput {
  title?: string | null;
  display_category_id_list?: string[] | null;
  properties?: SearchedGoodsPropertiesInput | null;
  attribute_list?: SearchedProductAttributeListInput[] | null;
  style_list?: SearchedProductStyleInput[] | null;
  price_range?: SearchedGoodsPriceRange | null;
  color_list?: string[] | null;
  is_selected_color_image_only?: boolean | null;
  purchase_age_list?: string[] | null;
  limit_count?: number | null;
  only_brand?: boolean | null;
  only_soho?: boolean | null;
  product_group_list?: string[] | null;
  model_size?: SearchedGoodsModelSizeInput | null;
  shop_id_list?: string[] | null;
  brand_id?: string | null;
}

/**
 * loginApple 의 입력
 */
export interface LoginAppleInput {
  code: string;
  bundle_id: string;
  full_name?: string | null;
}

export interface LoginFacebookInput {
  access_token: string;
}

export interface LoginGoogleInput {
  token: string;
}

/**
 * login의 입력
 */
export interface LoginInput {
  email: string;
  password: string;
  enable_auto_login?: boolean | null;
}

/**
 * loginKakao 의 입력
 */
export interface LoginKakaoInput {
  access_token: string;
  refresh_token: string;
  is_occupied_mobile_tel?: boolean | null;
  init_path?: string | null;
  mobile_tel?: string | null;
}

/**
 * 휴대폰 로그인 2차인증 공통 입력
 */
export interface LoginMobileWith2FAInput {
  authenticated_key: string;
  mobile_number: string;
}

export interface PriceWithCurrencyInput {
  currency: UxPaymentCurrency;
  decimal: number;
  price_without_decimal: number;
  display_currency: string;
  is_currency_prefix: boolean;
}

/**
 * processKmcAuthentication의 입력
 */
export interface ProcessKmcAuthenticationInput {
  rec_cert: string;
  cert_num: string;
}

/**
 * ProcessUserAccountAuthenticationResponse의 입력
 */
export interface ProcessUserAccountAuthenticationResponseInput {
  rec_cert: string;
  cert_num: string;
  is_adult_certification?: boolean | null;
}

/**
 * recertification 애플 방식의 입력
 */
export interface RecertificationAppleInput {
  code: string;
  bundle_id: string;
  access_page: string;
}

/**
 * recertificationPassword의 입력
 */
export interface RecertificationPasswordInput {
  password: string;
  access_page: string;
}

/**
 * recertification 소셜 방식의 입력
 */
export interface RecertificationSocialInput {
  access_token: string;
  access_page: string;
}

/**
 * reportProductReviewAbuse의 입력
 */
export interface ReportProductReviewAbuseInput {
  product_review_id: string;
  reported_reason_category: ProductReviewAbuseReportReasonCategory;
  reported_reason_detail?: string | null;
}

/**
 * ReportProductReviewUserReply의 입력
 */
export interface ReportProductReviewUserReplyInput {
  product_review_user_reply_id: string;
  include_user_account: boolean;
  reported_reason_category?: ProductReviewReplyAbuseReportReasonCategory | null;
  reported_reason_detail?: string | null;
}

/**
 * 본인인증으로 비밀번호 재설정 요청을 위한 입력값
 */
export interface RequestForResetPasswordWithAuthenticationInput {
  authenticated_key: string;
}

/**
 * requestUserAccountPasswordReset의 입력
 */
export interface RequestUserAccountPasswordResetInput {
  email: string;
}

export interface SearchedGoodsModelHeightRange {
  gte?: number | null;
  lte?: number | null;
}

export interface SearchedGoodsModelSizeInput {
  height_range?: SearchedGoodsModelHeightRange | null;
}

export interface SearchedGoodsPriceRange {
  gte?: number | null;
  gte_with_currency?: PriceWithCurrencyInput | null;
  lte?: number | null;
  lte_with_currency?: PriceWithCurrencyInput | null;
}

export interface SearchedGoodsPropertiesInput {
  length_list?: string[] | null;
  fit_list?: string[] | null;
  material_list?: string[] | null;
  pattern_list?: string[] | null;
  detail_list?: string[] | null;
  sleeve_length_list?: string[] | null;
}

export interface SearchedProductAttributeListInput {
  key: string;
  name: string;
  value: string;
}

export interface SearchedProductStyleInput {
  name: string;
  value: string;
}

/**
 * sendMobileAuthenticationToken의 입력
 */
export interface SendMobileAuthenticationTokenInput {
  mobile_number: string;
  app_hash_string?: string | null;
  expire_time_sec?: number | null;
  user_ip?: string | null;
  purpose_type?: string | null;
}

/**
 * setUserAccountBody의 입력
 */
export interface SetUserAccountBodyInput {
  height?: number | null;
  weight?: number | null;
  upper_size?: number | null;
  bottom_size?: number | null;
  shoe_size?: number | null;
  foot_shape?: UserAccountBodyFootShape[] | null;
  skin_type?: UserAccountBodySkinType | null;
  skin_tone?: UserAccountBodySkinTone | null;
  skin_tone_base?: UserAccountBodySkinToneBase | null;
  skin_concern?: UserAccountBodySkinConcern[] | null;
}

/**
 * signupApple 의 입력
 */
export interface SignupAppleInput {
  code: string;
  mobile_tel?: string | null;
  is_occupied_mobile_tel?: boolean | null;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  email?: string | null;
  ad_noti_agreed?: boolean | null;
  init_path?: string | null;
}

/**
 * signupFacebook 의 입력
 */
export interface SignupFacebookInput {
  access_token: string;
  mobile_tel?: string | null;
  is_occupied_mobile_tel?: boolean | null;
  email?: string | null;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  ad_noti_agreed?: boolean | null;
  init_path?: string | null;
}

/**
 * signupGoogle 의 입력
 */
export interface SignupGoogleInput {
  token: string;
  mobile_tel?: string | null;
  is_occupied_mobile_tel?: boolean | null;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  ad_noti_agreed?: boolean | null;
  init_path?: string | null;
}

/**
 * signup의 입력
 */
export interface SignupInput {
  email: string;
  password: string;
  mobile_tel?: string | null;
  sms_reception_agreed?: boolean | null;
  email_reception_agreed?: boolean | null;
  email_sms_reception_agreed?: boolean | null;
  nightly_ad_noti_agreed?: boolean | null;
  third_provide_agreed?: boolean | null;
  ad_noti_agreed?: boolean | null;
  init_path?: string | null;
}

/**
 * signupKakao 의 입력
 */
export interface SignupKakaoInput {
  access_token: string;
  is_occupied_mobile_tel?: boolean | null;
  init_path?: string | null;
  mobile_tel?: string | null;
}

export interface UiPropertyInput {
  column_count?: number | null;
}

/**
 * updateUserAccount의 입력
 */
export interface UpdateUserAccountInput {
  mobile_tel?: string | null;
  full_name?: string | null;
}

/**
 * updateUserLocale의 입력
 */
export interface UpdateUserLocaleInput {
  uuid?: string | null;
  country_id?: string | null;
  currency_id?: string | null;
  language_id?: string | null;
  default_codes?: UserLocaleDefaultCodeInput | null;
  default_aos_codes?: UserLocaleDefaultCodeInput | null;
  default_ios_codes?: UserLocaleDefaultCodeInput | null;
}

/**
 * user_locale이 존재하지 않을 경우 default code 입력값
 */
export interface UserLocaleDefaultCodeInput {
  country_code: string;
  currency_code: string;
  language_code: string;
}

/**
 * verifyMobileAuthenticationToken의 입력
 */
export interface VerifyMobileAuthenticationTokenInput {
  mobile_number: string;
  token: string;
}

export interface VerifyRecertificationInput {
  access_page: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

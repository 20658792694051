import { ProductCard as V4ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';

import { useMetadataPrice, UseMetadataPriceOptions } from '../hooks/useMetadataPrice';

export interface MetadataPriceProps extends UseMetadataPriceOptions, Pick<ProductCardProps, 'size'> {
  className?: string;
}

export const MetadataPrice = (props: MetadataPriceProps) => {
  const { className, size, price, salesStatus } = props;
  const { metadataPriceProps } = useMetadataPrice({ price, salesStatus });

  return (
    <V4ProductCard.MetadataItem className={className} marginTop={size === 'small' ? 2 : 4}>
      <V4ProductCard.MetadataPrice {...metadataPriceProps} />
    </V4ProductCard.MetadataItem>
  );
};

import { HStack, Text, useTheme } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

interface ServerCommonItem {
  normal: string | null;
  dark: string | null;
}

export interface OneDayDeliveryProps {
  text: string;
  color: ServerCommonItem | null;
  html: ServerCommonItem | null;
}

const systemStyle = css`
  // OneDayDelivery 밑에 들어가는 Metadata.Item의 margin-top이 4px이므로 Figma 디자인에 맞게 6px로 설정
  margin-bottom: 6px;
  @media (prefers-color-scheme: light) {
    .dark {
      display: none;
    }
  }
  @media (prefers-color-scheme: dark) {
    .light {
      display: none;
    }
  }
`;

export const OneDayDelivery = (props: OneDayDeliveryProps) => {
  const { html } = props;

  const { colorScheme } = useTheme();

  const light = html?.normal;
  const dark = html?.dark;

  if (!light && !dark) {
    return null;
  }

  if (colorScheme === 'system') {
    return (
      <HStack css={systemStyle}>
        {dark && (
          <Text
            className='dark'
            variant='Caption_11_Medium'
            color='gray600'
            dangerouslySetInnerHTML={{ __html: dark }}
          />
        )}
        {light && (
          <Text
            className='light'
            variant='Caption_11_Medium'
            color='gray600'
            dangerouslySetInnerHTML={{ __html: light }}
          />
        )}
      </HStack>
    );
  }

  if (colorScheme === 'dark') {
    const html = dark ?? light;

    if (!html) {
      return null;
    }

    return (
      <HStack css={systemStyle}>
        <Text variant='Caption_11_Medium' color='gray600' dangerouslySetInnerHTML={{ __html: html }} />
      </HStack>
    );
  }

  if (!light) {
    return null;
  }

  return (
    <HStack css={systemStyle}>
      <Text variant='Caption_11_Medium' color='gray600' dangerouslySetInnerHTML={{ __html: light }} />
    </HStack>
  );
};

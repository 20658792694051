import { ProductCard as V4ProductCard } from '@croquiscom-pvt/zds';

import { useThumbnailEmblem, UseThumbnailEmblemOptions } from '../hooks/useThumbnailEmblem';

export interface ThumbnailEmblemProps extends UseThumbnailEmblemOptions {
  className?: string;
}

export const ThumbnailEmblem = (props: ThumbnailEmblemProps) => {
  const { className, emblemBadgeList, size } = props;
  const { hasThumbnailEmblem, thumbnailEmblemProps } = useThumbnailEmblem({ emblemBadgeList, size });

  if (!hasThumbnailEmblem) return null;

  return <V4ProductCard.ThumbnailEmblem className={className} {...thumbnailEmblemProps} />;
};

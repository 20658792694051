import { HStack, ProductCard as V4ProductCard, ProductCardProps, VStack } from '@croquiscom-pvt/zds';

import { Fomo } from '../../components/Fomo';
import { MetadataReviewIcon } from '../../components/MetadataReviewIcon';
import { useMetadataFomo, UseMetadataFomoOptions } from '../hooks/useMetadataFomo';
import { useMetadataReview, UseMetadataReviewOptions } from '../hooks/useMetadataReview';

export interface MetadataReviewWithFomoProps
  extends UseMetadataReviewOptions,
    UseMetadataFomoOptions,
    Pick<ProductCardProps, 'size'> {
  className?: string;
}

export const MetadataReviewWithFomo = (props: MetadataReviewWithFomoProps) => {
  const { className, size, review, fomo } = props;

  const { hasReview, metadataReviewProps } = useMetadataReview({ review });
  const { hasFomo, metadataFomoProps } = useMetadataFomo({ fomo });

  if (!hasReview && !hasFomo) {
    return null;
  }

  if (size === 'small') {
    return (
      <V4ProductCard.MetadataItem className={className} marginTop={6}>
        <VStack spacing='3px'>
          {hasReview && <V4ProductCard.MetadataReview {...metadataReviewProps} icon={<MetadataReviewIcon />} />}
          {hasFomo && <Fomo size={size} {...metadataFomoProps} />}
        </VStack>
      </V4ProductCard.MetadataItem>
    );
  }

  return (
    <V4ProductCard.MetadataItem className={className} marginTop={8}>
      <HStack spacing='2px' blockAlign='center'>
        {hasReview && <V4ProductCard.MetadataReview {...metadataReviewProps} icon={<MetadataReviewIcon />} />}
        {hasFomo && <Fomo size={size} {...metadataFomoProps} />}
      </HStack>
    </V4ProductCard.MetadataItem>
  );
};

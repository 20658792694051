import noop from 'lodash/fp/noop';

import { isBrowser } from '../../../utils/conditions';
import { ZigzagHandler } from '../';

export const initialZigzagHandlers: ZigzagHandler = {
  onBookmarkStatus: noop,
  onKakaoLinkAvailable: noop,
  onSavedProductStatus: noop,
  onUserAccount: noop,
  onAppNotificationStatus: noop,
  onStatusbarHeight: noop,
  onSavedShopList: noop,
  onProductReviewListFilter: noop,
  onSavedMyGoods: noop,
  onRecentSearches: noop,
  onIsInstalledKakaoBank: noop,
};

export const injectZigzagHandler = <A>(args?: A) => {
  if (!isBrowser()) {
    return console.warn('Should invoke injectZigzagHandler in client');
  }

  const _window: any = window;
  if (!_window.zigzagHandler) {
    _window.zigzagHandler = initialZigzagHandlers;
  }
  return args;
};

/**
 * @example
 * hexToRGB('#FF0000', 0.5); // rgba(255, 0, 0, 1)
 * hexToRGB('#FF0000'); // rgba(255, 0, 0)
 */
export function hexToRGBA(hex: string, alpha_value?: number): string {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  const alpha = typeof alpha_value === 'undefined' ? '' : `, ${alpha_value}`;
  const rgb = `${red}, ${green}, ${blue}`;
  return `rgba(${rgb.concat(alpha)})`;
}

export function colorWithAlpha(color: string, alpha: number) {
  if (color.slice(0, 4) === 'rgba') {
    return color;
  }
  if (color.slice(0, 1) === '#') {
    return hexToRGBA(color, alpha);
  }
  if (color.slice(0, 3) === 'rgb') {
    return color.slice(0, color.length - 1) + `, ${alpha})`;
  }
  throw new Error('올바른 컬러 문자가 아닙니다.');
}

import { CSSProperties, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { ShapeIconArrowLeftRegular, ShapeIconArrowRightRegular } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/themes/vars';
import { ClassNames } from '@emotion/react';

import { SwiperClass, SwiperEvents, useSwiper } from '@common/carousel/swiper';

import { cssVariables, NAVIGATION_SIZE } from '../constants';

export interface ProductCardCarouselNavigationProps {
  className?: string;

  style?: CSSProperties;
}

export const ProductCardCarouselNavigation = (props: ProductCardCarouselNavigationProps) => {
  const { className, style } = props;
  const swiper = useSwiper();

  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);

  const slidesOffsetBefore = 8;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const type = event.currentTarget.dataset.swiperNavigationButtonType;

      switch (type) {
        case 'prev':
          event.preventDefault();
          if (!swiper.isBeginning && !swiper.params.loop && !swiper.params.rewind) {
            swiper.slidePrev();
          }
          return;
        case 'next':
          event.preventDefault();
          if (!swiper.isEnd && !swiper.params.loop && !swiper.params.rewind) {
            swiper.slideNext();
          }
          return;
        default:
          return;
      }
    },
    [swiper],
  );

  useEffect(() => {
    // Reference: https://github.com/nolimits4web/swiper/blob/f04718e92236bec8e9ea83b6c37a19c0b24a929a/src/modules/navigation/navigation.js#L143
    const events = ['toEdge', 'fromEdge', 'lock', 'unlock'] as Array<keyof SwiperEvents>;
    events.forEach((eventName) => swiper.on(eventName, handler));

    // 초기 호출
    handler(swiper);

    return () => {
      events.forEach((eventName) => swiper.off(eventName, handler));
    };

    // Reference: https://github.com/nolimits4web/swiper/blob/f04718e92236bec8e9ea83b6c37a19c0b24a929a/src/modules/navigation/navigation.js#L62
    function handler(swiper: SwiperClass) {
      const prev = prevRef.current;
      const next = nextRef.current;

      if (prev) {
        prev.classList.toggle('disabled', swiper.isBeginning && !swiper.params.rewind);
      }
      if (next) {
        next.classList.toggle('disabled', swiper.isEnd && !swiper.params.rewind);
      }
    }
  }, [swiper]);

  return (
    <ClassNames>
      {({ cx, css }) => {
        const buttonCss = css({
          [cssVariables.navigationOffset]: `${slidesOffsetBefore}px`,
          [`&`]: {
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
            top: '50%',
            width: `${NAVIGATION_SIZE}px`,
            height: `${NAVIGATION_SIZE}px`,
            transform: `translateY(-${NAVIGATION_SIZE}px)`,
            padding: '6px',
            backgroundColor: vars.colors.staticWhite,
            cursor: 'pointer',
            borderRadius: vars.cornerRadius.full,
            boxShadow: vars.shadow.small,
          },
          [`&.disabled`]: {
            display: 'none',
          },
          [`&.prev`]: {
            left: [`${slidesOffsetBefore}px`, `var(${cssVariables.navigationOffset})`],
          },
          [`&.next`]: {
            right: [`${slidesOffsetBefore}px`, `var(${cssVariables.navigationOffset})`],
          },
        });

        return (
          <>
            <button
              ref={prevRef}
              data-swiper-navigation-button-type='prev'
              className={cx(buttonCss, 'prev', className)}
              style={style}
              onClick={handleClick}
            >
              <ShapeIconArrowLeftRegular color='gray600' size={20} />
            </button>
            <button
              ref={nextRef}
              data-swiper-navigation-button-type='next'
              className={cx(buttonCss, 'next', className)}
              style={style}
              onClick={handleClick}
            >
              <ShapeIconArrowRightRegular color='gray600' size={20} />
            </button>
          </>
        );
      }}
    </ClassNames>
  );
};

import { forwardRef } from 'react';
import { ProductCard as V4ProductCard, ProductCardThumbnailLikeButtonProps } from '@croquiscom-pvt/zds';

import { cardHeartOffIconFor4, cardHeartOnIconFor4 } from '../constants';
import { useProductCardUseLikeObserverContext } from '../ProductCardContext';

export interface ThumbnailLikeButtonProps {
  className?: string;
  productId: string | null;
  onLikeClick?: (checked: boolean, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const ThumbnailLikeButton = forwardRef<HTMLSpanElement, ThumbnailLikeButtonProps>((props, ref) => {
  const { className, productId, onLikeClick } = props;
  const useLikeObserver = useProductCardUseLikeObserverContext();
  const { isLiked, ref: likeRef } = useLikeObserver(productId ?? '');

  const handleChange: ProductCardThumbnailLikeButtonProps['onChange'] = (checked, event) => {
    if (!onLikeClick) return;
    event.stopPropagation();
    return onLikeClick(checked, event);
  };

  return (
    <V4ProductCard.ThumbnailLikeButton
      ref={(node) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
        if (node) {
          likeRef.current = node;
        }
      }}
      className={className}
      checked={isLiked}
      icon={cardHeartOffIconFor4}
      checkedIcon={cardHeartOnIconFor4}
      onChange={handleChange}
    />
  );
});

import { ProductCard as V4ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';

import { useMetadataPrice, UseMetadataPriceOptions } from '../hooks/useMetadataPrice';
import { BadgeListItemProps } from '../types';

export interface MetadataPriceProps extends UseMetadataPriceOptions, Pick<ProductCardProps, 'size'> {
  className?: string;
  thumbnailEmblemBadgeList?: BadgeListItemProps[];
}

export const MetadataPrice = (props: MetadataPriceProps) => {
  const { className, price, emblemItems, salesStatus } = props;
  const { metadataPriceProps } = useMetadataPrice({ price, emblemItems, salesStatus });

  return (
    <V4ProductCard.MetadataItem className={className} marginTop={3}>
      <V4ProductCard.MetadataPrice {...metadataPriceProps} />
    </V4ProductCard.MetadataItem>
  );
};

import { ReactNode } from 'react';
import { ProductCard as V4ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import clsx from 'clsx';

import { useThumbnail, UseThumbnailOptions } from '../hooks/useThumbnail';

export interface ThumbnailProps extends UseThumbnailOptions, Pick<ProductCardProps, 'size'> {
  className?: string;
  children: ReactNode;
}

export const Thumbnail = (props: ThumbnailProps) => {
  const { size, className, imageUrl, webpImageUrl, width, ratio, children } = props;

  const { thumbnailProps } = useThumbnail({
    imageUrl,
    webpImageUrl,
    width,
    ratio,
  });

  return (
    <V4ProductCard.Thumbnail className={clsx([size, className])} {...thumbnailProps}>
      {children}
    </V4ProductCard.Thumbnail>
  );
};

import { ProductCardProps, Text } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

export interface FomoProps extends Pick<ProductCardProps, 'size'> {
  text: string;
  iconImageUrl?: string | null;
}

const wrapperCss = css`
  display: inline-flex;
  align-items: center;
`;

const dotCss = css`
  margin-right: 2px;
`;

export const Fomo = (props: FomoProps) => {
  const { size, text } = props;

  if (size === 'small') {
    return (
      <div css={wrapperCss}>
        <Text variant='Caption_10_Regular' color='gray400'>
          {text}
        </Text>
      </div>
    );
  }

  return (
    <div css={wrapperCss}>
      <Text css={dotCss} variant='Caption_11_Regular' color='gray400'>
        ·
      </Text>
      <Text variant='Caption_11_Regular' color='gray400'>
        {text}
      </Text>
    </div>
  );
};

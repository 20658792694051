import { forwardRef } from 'react';
import { IconHeartRegular, IconHeartSolid, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

import { useProductCardUseLikeObserverContext } from '../../ProductCardContext';

export interface MetadataLikeButtonProps extends Pick<ProductCardProps, 'size'> {
  className?: string;
  productId: string | null;
  onLikeClick?: (checked: boolean, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const wrapperCss = css`
  display: inline-flex;
  flex-shrink: 0;
  margin-left: 12px;
  align-self: center;
  z-index: 1;
  padding: 8px;
  box-sizing: border-box;
`;

export const MetadataLikeButton = forwardRef<HTMLSpanElement, MetadataLikeButtonProps>((props, ref) => {
  const { className, size, productId, onLikeClick } = props;
  const useLikeObserver = useProductCardUseLikeObserverContext();
  const { isLiked, ref: likeRef } = useLikeObserver(productId ?? '');

  const iconSize = size === 'small' ? 24 : 28;

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!onLikeClick) return;
    event.stopPropagation();
    return onLikeClick(!isLiked, event);
  };

  return (
    <span
      ref={(node) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
        likeRef.current = node;
      }}
      css={wrapperCss}
      className={className}
      onClick={handleClick}
    >
      {isLiked ? (
        <IconHeartSolid size={iconSize} color='red200' />
      ) : (
        <IconHeartRegular size={iconSize} color='gray400' />
      )}
    </span>
  );
});

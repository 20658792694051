import React, { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import type { ProductCardMetadataTitleProps, ProductCardProps } from '@croquiscom-pvt/zds';
import debounce from 'lodash/debounce';

import { UxGoodsCardItemForVerticalProductCard } from '../../../vertical-product-card/types';
import { getVerticalProductCardPropsByUxGoodsCardItemForVerticalProductCard } from '../../../vertical-product-card/utils';
import { VerticalProductCard, VerticalProductCardProps } from '../../../vertical-product-card/VerticalProductCard';

export interface UseVerticalProductCardWithItemOptions<T extends UxGoodsCardItemForVerticalProductCard>
  extends Pick<ProductCardProps, 'size'>,
    Pick<ProductCardMetadataTitleProps, 'productNameLineClamp'>,
    Pick<VerticalProductCardProps, 'metadataPadding'> {
  onImpression?: (item: T) => void;
  onClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: T, event: React.MouseEvent<HTMLSpanElement>) => void;
}

export function useVerticalProductCardWithItem<T extends UxGoodsCardItemForVerticalProductCard>(
  item: T,
  options?: UseVerticalProductCardWithItemOptions<T>,
): Pick<
  React.ComponentPropsWithRef<typeof VerticalProductCard>,
  'ref' | 'size' | 'soldOut' | 'metadataPadding' | 'onClick' | 'onLikeClick'
> &
  ReturnType<typeof getVerticalProductCardPropsByUxGoodsCardItemForVerticalProductCard> {
  const { size, productNameLineClamp, metadataPadding, onImpression, onClick, onLikeClick } = options ?? {};

  const onImpressionRef = useRef(onImpression);
  const onClickRef = useRef(onClick);
  const onLikeClickRef = useRef(onLikeClick);

  const { ref } = useInView({
    onChange: debounce((inView) => {
      if (inView && onImpressionRef.current) {
        onImpressionRef.current(item);
      }
    }, 300),
    skip: !onImpression,
  });

  const common = getVerticalProductCardPropsByUxGoodsCardItemForVerticalProductCard(item);

  if (productNameLineClamp !== undefined) {
    common.metadata.title.productNameLineClamp = productNameLineClamp;
  }

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (onClickRef.current) {
        onClickRef.current(item, event);
      }
    },
    [item],
  );

  const handleLikeClick = useCallback(
    (isLiked: boolean, event: React.MouseEvent<HTMLSpanElement>) => {
      if (onLikeClickRef.current) {
        onLikeClickRef.current(isLiked, item, event);
      }
    },
    [item],
  );

  useEffect(() => {
    onImpressionRef.current = onImpression;
  }, [onImpression]);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    onLikeClickRef.current = onLikeClick;
  }, [onLikeClick]);

  return {
    ref,
    size,
    metadataPadding,
    ...common,
    onClick: handleClick,
    onLikeClick: handleLikeClick,
  };
}

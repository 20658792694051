import React, { forwardRef } from 'react';
import {
  ProductCard as V4ProductCard,
  ProductCardMetadataProps as V4ProductCardMetadataProps,
  ProductCardProps,
} from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';
import clsx from 'clsx';

export interface MetadataRootProps extends V4ProductCardMetadataProps, Pick<ProductCardProps, 'size'> {
  className?: string;

  /**
   * 메타데이터 영역의 정렬을 지정합니다.
   * @default start
   */
  align?: 'start' | 'center';

  children: React.ReactNode;
}

const wrapperCss = css`
  flex-grow: 1;

  &.start {
    align-self: flex-start;
  }
  &.center {
    align-self: center;
  }
`;

export const MetadataRoot = forwardRef<HTMLDivElement, MetadataRootProps>((props, ref) => {
  const { className, size, align = 'start', children } = props;

  return (
    <V4ProductCard.Metadata css={wrapperCss} className={clsx([size, className])}>
      {children}
    </V4ProductCard.Metadata>
  );
});

import { forwardRef, ReactNode } from 'react';
import {
  ProductCard as V4ProductCard,
  ProductCardMetadataProps as V4ProductCardMetadataProps,
  ProductCardProps,
} from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';
import clsx from 'clsx';

export interface MetadataRootProps extends V4ProductCardMetadataProps, Pick<ProductCardProps, 'size'> {
  className?: string;
  /** @default false */
  padding?: boolean;
  children: ReactNode;
}

const metadataCss = css`
  padding-left: 0px;
  padding-right: 0px;

  &.small {
    padding-right: 10px;
  }
  &.medium {
    padding-right: 16px;
  }
  &.large {
    padding-right: 16px;
  }

  &.small.padding-on {
    padding-left: 10px;
  }
  &.medium.padding-on {
    padding-left: 16px;
  }
  &.large.padding-on {
    padding-left: 16px;
  }
`;

export const MetadataRoot = forwardRef<HTMLDivElement, MetadataRootProps>((props, ref) => {
  const { className, size, padding, children } = props;

  return (
    <V4ProductCard.Metadata
      ref={ref}
      css={metadataCss}
      className={clsx([size, padding ? 'padding-on' : 'padding-off', className])}
    >
      {children}
    </V4ProductCard.Metadata>
  );
});

import { useEffect, useState } from 'react';

export const useZigzag = (defaultValue?: boolean) => {
  const [is_app, setIsApp] = useState<boolean | null>(defaultValue || null);

  useEffect(() => {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    setIsApp(HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent));
  }, []);

  return is_app;
};

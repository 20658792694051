import getConfig from 'next/config';

import { SiteId } from '@common/site-manager';

/**
 * 공개 런타임 구성을 검색합니다.
 * @returns 공개 런타임 구성입니다.
 */
export function getPublicRuntimeConfig() {
  return getConfig().publicRuntimeConfig.config;
}

/**
 * 런타임 구성에서 특정 키의 값을 검색합니다.
 * 값이 객체인 경우, 제공된 사이트 ID에 해당하는 값을 반환합니다.
 * 사이트 ID가 제공되지 않은 경우, 지그재그에 해당하는 값을 반환합니다.
 * @param key - 런타임 구성에서 검색할 값의 키입니다.
 * @param site_id - 선택 사항. 값을 검색할 사이트 ID입니다. 지그재그입니다.
 * @returns 런타임 구성에서 제공된 키와 사이트 ID에 해당하는 값입니다.
 */
export function getRuntimeConfigValue(key: keyof BaseConfigType, site_id?: SiteId) {
  const config: BaseConfigType = getPublicRuntimeConfig();
  const value = config[key];

  if (typeof value === 'object') {
    return value[site_id || SiteId.ZIGZAG];
  } else {
    return value;
  }
}

import { useMemo } from 'react';
import { css } from '@emotion/react';

import { SwiperSlide } from '@common/carousel/swiper';

import {
  VerticalProductCardWithItem,
  VerticalProductCardWithItemProps,
} from '../../components/vertical-product-card-with-item/VerticalProductCardWithItem';
import { UxGoodsCardItemForVerticalProductCard } from '../../vertical-product-card/types';
import { useProductCardCarouselContext } from '../Carousel';
import {
  SLIDE_ITEM_MAX_WIDTH_2_5,
  SLIDE_ITEM_MAX_WIDTH_2_12,
  SLIDE_ITEM_MAX_WIDTH_3_14,
  SLIDE_ITEM_MIN_WIDTH_2_5,
  SLIDE_ITEM_MIN_WIDTH_2_12,
  SLIDE_ITEM_MIN_WIDTH_3_14,
} from '../constants';
import { getSlidesPerViewClassNameMap } from '../getSlidesPerViewClassNameMap';
import { VerticalProductCardSizer, VerticalProductCardSizerProps } from './VerticalProductCardSizer';

const swiperSlideClassNameMap = getSlidesPerViewClassNameMap('vertical-product-card-slide-items-swiper-slide');

export interface ProductCardCarouselVerticalProductCardSlideItemsProps
  extends Pick<VerticalProductCardSizerProps, 'defaultSize'>,
    Pick<VerticalProductCardWithItemProps<UxGoodsCardItemForVerticalProductCard>, 'productNameLineClamp'> {
  items: UxGoodsCardItemForVerticalProductCard[];
  onImpression?: (item: UxGoodsCardItemForVerticalProductCard, index: number) => void;
  onClick?: (item: UxGoodsCardItemForVerticalProductCard, index: number, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: UxGoodsCardItemForVerticalProductCard, index: number) => void;
}

export const ProductCardCarouselVerticalProductCardSlideItems = (
  props: ProductCardCarouselVerticalProductCardSlideItemsProps,
) => {
  const { defaultSize, items, productNameLineClamp, onClick, onImpression, onLikeClick } = props;

  const { cssMode, spaceBetween, slidesOffsetBefore, slidesPerView } = useProductCardCarouselContext();

  const slideWidth = useMemo(() => {
    /* 
      계산 공식: width = (스크린 너비 - 캐러셀 좌측 컨테이너 여백 - 캐러셀 아이템 간 여백 - (스크린 너비 당 슬라이드 비율 - 1)) / 스크린 너비 당 슬라이드 비율
      예시:
        - 스크린 너비: 375px
        - 캐러셀 좌측 컨테이너 여백: 16px
        - 캐러셀 아이템 간 여백: 2px
        - 스크린 너비 당 슬라이드 비율: 3.14
        - 계산 결과: (375px - 16px - 2px * (3.14 - 1)) / 3.14 = 113.0127388535px
        - 위 계산 결과 로직 최적화: calc(31.8471% - 6.4586px)
      관련 논의 스레드: https://croquis.slack.com/archives/C06AKCJSR0E/p1719540129166739 
    */

    const totalSpacing = spaceBetween * (slidesPerView - 1);
    const totalOffset = totalSpacing + (cssMode ? -slidesOffsetBefore : slidesOffsetBefore);
    const spacingPerSlide = totalOffset / slidesPerView;

    return `calc(${100 / slidesPerView}% - ${spacingPerSlide}px)`;
  }, [cssMode, slidesOffsetBefore, slidesPerView, spaceBetween]);

  return (
    <>
      {items.map((item, index) => {
        return (
          <SwiperSlide
            css={wrapperCss}
            className={swiperSlideClassNameMap[slidesPerView]}
            style={{ width: slideWidth }}
            key={index}
          >
            <VerticalProductCardSizer defaultSize={defaultSize}>
              {({ ref, size }) => (
                <VerticalProductCardWithItem
                  ref={ref}
                  size={size}
                  item={item}
                  productNameLineClamp={productNameLineClamp}
                  onImpression={(item) => onImpression?.(item, index)}
                  onClick={(item, event) => onClick?.(item, index, event)}
                  onLikeClick={(isLiked, item) => onLikeClick?.(isLiked, item, index)}
                />
              )}
            </VerticalProductCardSizer>
          </SwiperSlide>
        );
      })}
    </>
  );
};

const wrapperCss = css`
  /* 기존 PDP 캐러셀에 있던 개발 로직. 상품카드 캐러셀 컴포넌트 시스템화 관련해서 디자이너분이랑 논의 후 로직이 변경될 수 있습니다. */
  &.${swiperSlideClassNameMap['2.12']} {
    min-width: ${SLIDE_ITEM_MIN_WIDTH_2_12}px;
    max-width: ${SLIDE_ITEM_MAX_WIDTH_2_12}px;
  }
  &.${swiperSlideClassNameMap['2.5']} {
    min-width: ${SLIDE_ITEM_MIN_WIDTH_2_5}px;
    max-width: ${SLIDE_ITEM_MAX_WIDTH_2_5}px;
  }
  &.${swiperSlideClassNameMap['3.14']} {
    min-width: ${SLIDE_ITEM_MIN_WIDTH_3_14}px;
    max-width: ${SLIDE_ITEM_MAX_WIDTH_3_14}px;
  }
`;

// Swiper 라이브러리 이슈 때문에 displayName을 설정해줍니다. https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
ProductCardCarouselVerticalProductCardSlideItems.displayName = 'SwiperSlide';
